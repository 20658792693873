import { RootState } from '../redux/reducers';
import {
  deleteAdminInvites,
  deleteAdmins,
  setAdminInvites,
  setAdmins,
  updateRole,
} from '../redux/slices/adminSlice';
import {
  addBusiness,
  deleteBusiness,
  setOnboarding,
  wipeSelectedBusiness,
} from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import { ProfileField } from '../types/Profile';
import Business, { Admin, AdminInvite, AdminRole } from '../types/business';
import { OnboardingStep, PersonalConnection } from '../types/misc';
import authService from './authService';
import httpService, { getUserId } from './httpService';
import { AdminInviteData, CreateAdminInviteRequest } from './model/adminService.model';

const adminService = {
  sendAdminInvites: async (emails: string[], role: AdminRole) => {
    const body: CreateAdminInviteRequest = {
      emails,
      role,
    };
    return httpService.post<AdminInvite[]>('/business/admin/invite', body).then(({ data }) => {
      const adminInvites = (store.getState() as RootState).admins.invites;
      store.dispatch(setAdminInvites([...data, ...adminInvites]));
      return data;
    });
  },

  getAdminInvite: async (token: string): Promise<AdminInviteData> => {
    return httpService
      .get<AdminInviteData>(`/business-admin/invite/${token}`)
      .then((res) => res.data);
  },

  acceptInvite: async (token: string): Promise<void> => {
    return httpService
      .post<Business>('business-admin/invite/accept', { token })
      .then(({ data }): void => {
        store.dispatch(addBusiness(data));
      });
  },

  registerAndAccept: async (token: string, email: string, alias: string): Promise<void> => {
    await authService.register(alias, email);
    await adminService.acceptInvite(token);
  },

  requestDifferentEmail: async (token: string): Promise<void> => {
    return httpService.post('/business-admin/invite/different-email-request', { token });
  },

  getAllAdmins: (): Promise<Admin[]> => {
    return httpService.get<Admin[]>('/business/admin').then(({ data }) => {
      store.dispatch(setAdmins(data));
      // if (data.find((a) => a.userId === getUserId())?.onboarding)
        // store.dispatch(setOnboarding(OnboardingStep.INIT));
      return data;
    });
  },

  getAllAdminInvites: (): Promise<AdminInvite[]> => {
    return httpService.get<AdminInvite[]>('/business/admin/invite').then(({ data }) => {
      store.dispatch(setAdminInvites(data));
      return data;
    });
  },

  checkAdmin: (email: string): Promise<boolean> => {
    return httpService
      .get<boolean>(`/business/admin/check?email=${email}`)
      .then(({ data }) => data);
  },

  deleteAdmins: (adminIds: number[]): Promise<void> => {
    return httpService.delete('/business/admin', { data: { adminIds } }).then(() => {
      store.dispatch(deleteAdmins(adminIds));
    });
  },

  deleteAdminInvites: (inviteIds: number[]): Promise<void> => {
    return httpService.delete('/business/admin/invite', { data: { inviteIds } }).then(() => {
      store.dispatch(deleteAdminInvites(inviteIds));
    });
  },

  upgradeAdminToOwner: (adminId: number): Promise<void> => {
    return httpService.put(`/business/admin/owner/${adminId}`).then(() => {
      store.dispatch(updateRole({ adminId, role: AdminRole.OWNER }));
    });
  },

  resendInviteLink: (inviteId: number): Promise<void> => {
    return httpService.post(`/business/admin/invite/resend/${inviteId}`);
  },

  leaveBusiness: (): Promise<void> => {
    return httpService.delete('/business/admin/self').then(() => {
      const id = store.getState().application.selectedBusiness;
      store.dispatch(wipeSelectedBusiness());
      store.dispatch(deleteBusiness(id));
    });
  },

  getNonAdminConnections: (): Promise<PersonalConnection[]> => {
    return httpService
      .get<PersonalConnection[]>('/business/admin/connections')
      .then(({ data }) => data);
  },

  inviteByUserIds: (userIds: number[], role: AdminRole): Promise<void> => {
    return httpService
      .post<AdminInvite[]>('/business/admin/invite/connections', { userIds, role })
      .then(({ data }) => {
        store.dispatch(setAdminInvites([...data, ...store.getState().admins.invites]));
      });
  },

  getSharedData: (): Promise<ProfileField[]> => {
    return httpService.get<ProfileField[]>('/business/admin/shared-data').then(({ data }) => data);
  },

  updateSharedData: (propertyIds: number[]): Promise<void> => {
    return httpService.put('/business/admin/shared-data', { propertyIds });
  },

  setFinishedOnboarding: (): Promise<void> => {
    return httpService.patch('/business/admin/onboarding').then(() => {
      store.dispatch(setOnboarding(undefined));
    });
  },
};
export default adminService;
