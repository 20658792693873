import React, { ButtonHTMLAttributes } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { isMoments } from '../../../constants';
import Button from '../buttons/Button';
import { findReactElement, replaceReactElement } from '../../../utils/reactUtils';

const frontendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : isMoments
      ? 'https://veramoments.app'
      : 'https://veracommunity.app';
interface CheckoutFormProps {
  clientSecret: string;
  disabled: boolean;
  setDisabled: any;
  onSubmit: any;
  redirectQueryParams?: string;
  intentType?: string;
  className?: string;
  containerClassName?: string;
  buttonClassName?: string;
  children?: React.ReactNode;
}

export default function CheckoutForm({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  clientSecret,
  onSubmit,
  disabled,
  setDisabled,
  className = '',
  redirectQueryParams = '',
  intentType = 'payment',
  containerClassName = '',
  buttonClassName = '',
  children,
}: CheckoutFormProps): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDisabled(true);
    try {
      await onSubmit();
    } catch (error) {
      setDisabled(false);
      return;
    }
    let error;
    if (intentType === 'setup') {
      ({ error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${frontendUrl}/payment/completion/${redirectQueryParams}`,
        },
      }));
    } else if (intentType === 'setupChange') {
      ({ error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${frontendUrl}/subscriptions/manage/`,
        },
      }));
    } else {
      ({ error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${frontendUrl}/payment/completion/${redirectQueryParams}`,
        },
      }));
    }
    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        toast.error(error.message!);
      } else {
        toast.error(t('toast.error.unexpected'));
      }
    }
    setDisabled(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    loader: 'always',
  } as StripePaymentElementOptions;

  const button = findReactElement(children, CheckoutForm.CheckoutButton);
  if (button) {
    children = replaceReactElement(
      children,
      button,
      React.cloneElement(button, {
        onClick: handleSubmit,
      }),
    );
  }

  return (
    <div
      data-testid="checkout-form"
      id="payment-form relative"
      className={twMerge('w-full', className)}>
      <PaymentElement
        data-testid="stripe-form"
        className={twMerge('my-4 mb-8 w-full rounded-[20px] p-4', containerClassName)}
        options={paymentElementOptions}
      />
      {children ?? (
        <div className="flex justify-end">
          <Button
            variant="primary"
            className={buttonClassName}
            disabled={disabled}
            onClick={handleSubmit}>
            {intentType === 'setupChange'
              ? t('page.payment.confirm')
              : t('page.payment.payAndConfirm')}
          </Button>
        </div>
      )}
    </div>
  );
}

// props of Button using typeof Button
function CheckoutButton(props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>) {
  return <Button variant="primary" {...props} />;
}

CheckoutForm.CheckoutButton = CheckoutButton;
