import React from 'react';
import { useDispatch } from 'react-redux';
import { FaCheck } from 'react-icons/fa';
import { FaChevronDown, FaPlus } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import HoverButton from '../../../buttons/HoverButton';
import { isMoments } from '../../../../../constants';
import useSelectedBusiness from '../../../../../hooks/business/useSelectedBusiness';
import useBusinesses from '../../../../../hooks/business/useBusinesses';
import { BUTTON_STYLE, LABEL_STYLE } from './style';
import { businessTypeIcons } from '../../../../../utils/iconUtils';
import Icon from '../../../icons/Icon';
import {
  setSelectedBusiness,
  wipeSelectedBusiness,
} from '../../../../../redux/slices/applicationSlice';
import useBusinessTranslation from '../../../../../hooks/useBusinessTranslation';

export default function BusinessButton() {
  const business = useSelectedBusiness();
  const businesses = useBusinesses();
  const dispatch = useDispatch();
  const { t } = useBusinessTranslation();

  if (!business) return null;

  const handleBusinessSelect = (businessId: number) => {
    dispatch(wipeSelectedBusiness());
    dispatch(setSelectedBusiness(businessId));
  };

  const label = isMoments ? 'Moment' : 'Community';
  return (
    <HoverButton>
      <HoverButton.Button>
        <div className="flex w-fit items-center justify-between gap-2 rounded-full px-3 py-[8px] text-[18px] transition-all hover:bg-primary-300 hover:bg-opacity-20 group-[.expanded]:bg-primary-300 group-[.expanded]:bg-opacity-20">
          <div className="flex gap-2">
            <span className="font-serif">{label}:</span>
            <span className="font-medium text-primary">{business.name}</span>
          </div>
          <FaChevronDown className="h-4 w-4 text-primary" />
        </div>
      </HoverButton.Button>
      <HoverButton.Div className="mt-2 flex w-full min-w-[250px] flex-col gap-[10px] rounded-[28px] border border-primary-300 bg-primary-900 p-3">
        <div className="w-full font-serif text-xl text-secondary-50">{label}:</div>
        <div className="w-full border border-primary-300" />
        {(businesses?.filter(b => b.shareWithAdmins) ?? []).map((b) => (
          <div
            key={b.businessId}
            onClick={() => handleBusinessSelect(b.businessId!)}
            className={twMerge(
              BUTTON_STYLE,
              'cursor-pointer items-center justify-between px-3 py-2 leading-[19px]',
              classNames({
                'bg-primary-300 text-primary-900 hover:bg-opacity-100':
                  business.businessId === b.businessId,
              }),
            )}>
            <div className="flex w-full items-center gap-2">
              <Icon icon={businessTypeIcons[b.type]} className="h-5 w-5 flex-shrink-0" />
              <p
                className={twMerge(
                  LABEL_STYLE,
                  'w-full overflow-hidden text-ellipsis pr-2 font-medium',
                  business.businessId === b.businessId && 'text-primary-900',
                )}>
                {b.name}
              </p>
            </div>
            {business.businessId === b.businessId && (
              <FaCheck className="h-3 w-3 flex-shrink-0 text-primary-900" />
            )}
          </div>
        ))}
        <div className="w-full border border-primary-300" />
        <div className="flex w-full flex-shrink-0 flex-col">
          <Link to="/onboarding" className={twMerge(BUTTON_STYLE, 'gap-2 px-3 py-2 text-sm')}>
            <FaPlus className={twMerge(LABEL_STYLE, 'h-5 w-5 text-primary-300')} />
            <p
              className={twMerge(
                LABEL_STYLE,
                'text-base font-medium leading-[19px] text-primary-300',
              )}>
              {t('page.businessOverview.new')}
            </p>
          </Link>
        </div>
      </HoverButton.Div>
    </HoverButton>
  );
}
