import { TFunction } from 'i18next';
import dayjs from 'dayjs';
import { Frequency } from '../types/event';

function getDatesBetween(startDate: Date, endDate: Date, interval: Frequency): Date[] {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));

    switch (interval) {
      case Frequency.DAILY:
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case Frequency.WEEKLY:
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case Frequency.BI_WEEKLY:
        currentDate.setDate(currentDate.getDate() + 14);
        break;
      case Frequency.MONTHLY:
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case Frequency.YEARLY:
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      default:
        throw new Error('Invalid interval');
    }
  }
  return dates;
}

function calculateDayDifference(startDate: Date, endDate: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  return Math.round(Math.abs((endDay.getTime() - startDay.getTime()) / oneDay));
}

function timeDifferenceString(createdAt: Date | string, t: TFunction) {
  const units = [
    { label: t('time.yearText'), value: 365 * 24 * 60 * 60 * 1000 },
    { label: t('time.monthText'), value: 30 * 24 * 60 * 60 * 1000 },
    { label: t('time.weekText'), value: 7 * 24 * 60 * 60 * 1000 },
    { label: t('time.dayText'), value: 24 * 60 * 60 * 1000 },
    { label: t('time.hourText'), value: 60 * 60 * 1000 },
    { label: t('time.minuteText'), value: 60 * 1000 },
  ];

  const previous = new Date(createdAt).valueOf();
  const current = new Date().valueOf();
  const elapsed = current - previous;

  const unit = units.find(({ value }) => elapsed >= value);

  if (unit) {
    const count = Math.floor(elapsed / unit.value);
    return `${count} ${unit.label}`;
  }

  return t('time.justNow');
}

const roundToNearest5Minutes = (date: Date) => {
  const dayjsDate = dayjs(date);
  const roundedMinute = Math.round(dayjsDate.minute() / 5) * 5;
  const roundedDate = dayjsDate.startOf('hour').add(roundedMinute, 'minute');
  return roundedDate.toDate();
};

const setHoursToZero = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

const isInBounds = (date: Date, startDate?: Date, endDate?: Date) => {
  if (!startDate || !endDate) return true;

  const startDateMidnight = new Date(startDate);
  startDateMidnight.setHours(0, 0, 0, 0);

  const endDateMidnight = new Date(endDate);
  endDateMidnight.setHours(23, 59, 59, 999);

  return date >= startDateMidnight && date <= endDateMidnight;
}

export default {
  getDatesBetween,
  calculateDayDifference,
  timeDifferenceString,
  roundToNearest5Minutes,
  setHoursToZero,
  isInBounds,
};
