import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuListPlus, LuListRestart } from 'react-icons/lu';
import ModalLayout from '../layouts/ModalLayout';
import IconBadge from '../icons/IconBadge';
import Labeled from '../misc/Labeled';
import Toggle from '../misc/Toggle';
import Button from '../buttons/Button';

interface ScoreCardModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit?: (data: ScoreCardFormData) => void;
  onClosed?: () => void;
  initialState?: ScoreCardFormData;
}

type ScoreCardFormData = {
  name: string;
  memberEditAccess: boolean;
}

export default function ScoreCardModal({
  open, setOpen, onSubmit, initialState, onClosed
}: ScoreCardModalProps): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score.forms' });
  const [scoreList, setScoreList] = React.useState<ScoreCardFormData>({ name: '', memberEditAccess: false  });
  const editMode = useMemo(() => !!initialState, [initialState]);

  useEffect(() => {
    if (initialState) {
      setScoreList(initialState);
    } else {
      setScoreList({
        name: '',
        memberEditAccess: false,
      });
    }
  }, [initialState]);

  useEffect(() => {
    if (!open && onClosed) {
      onClosed();
    }

    if (!open && !initialState) {
      setScoreList({
        name: '',
        memberEditAccess: false,
      });
    }
  }, [open, onClosed]);

  return (
    <ModalLayout open={open} setOpen={setOpen} closeButton
                 className="z-50 w-full max-w-[600px] rounded-xl bg-secondary-50 p-6">
      <div className="flex items-center border-b border-secondary-200 gap-4 pb-2">
        <IconBadge icon={initialState ? LuListRestart : LuListPlus} />
        <h2 className="font-semibold text-lg">{editMode ? t('editTitle') : t('createTitle')}</h2>
      </div>
      <div className="flex flex-col gap-4 pt-2">
        <Labeled label={t('name')}>
          <input
            value={scoreList.name}
            onChange={(ev) => setScoreList(prev => ({ ...prev, name: ev.target.value }))}
            type="text" placeholder={t('namePlaceholder')}
          />
        </Labeled>
        <div className="flex items-center">
          <Toggle
            state={scoreList.memberEditAccess}
            handleToggle={() => setScoreList((prev) => ({
              ...prev,
              memberEditAccess: !prev.memberEditAccess,
            }))}
          />
          <label>{t('memberAccessLabel')}</label>
        </div>
        <Button variant="primary" className="self-end" onClick={() => {
          onSubmit?.(scoreList);
          setOpen(false);
        }}>
          {editMode ? t('editButton') : t('createButton')}
        </Button>
      </div>
    </ModalLayout>
  );
}
