import React, { useEffect } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  TbCalendar,
  TbChevronRight,
  TbCircleCheck,
  TbMailOpened,
  TbNotification,
  TbSettingsCheck,
  TbUserCheck,
  TbUsers,
} from 'react-icons/tb';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { FaCircleCheck, FaRegCircle } from 'react-icons/fa6';
import { twMerge } from 'tailwind-merge';
import PageHeader from '../components/misc/PageHeader';
import { Event, EventViewType } from '../../types/event';
import useSelectedBusiness from '../../hooks/business/useSelectedBusiness';
import Button from '../components/buttons/Button';
import connectionService from '../../services/connectionService';
import { RootState } from '../../redux/reducers';
import eventService from '../../services/eventService';
import useEvents from '../../hooks/business/useEvents';
import OverviewCard from '../components/cards/OverviewCard';
import ProgressBar from '../components/misc/ProgressBar';
import { isConnectionVerafied } from '../../utils/connectionUtils';
import { COLOR_PRIMARY_300, COLOR_PRIMARY_900, COLOR_SECONDARY_200 } from '../../constants';
import communicationsService from '../../services/communicationsService';
import useCommunications from '../../hooks/business/useCommunications';
import { CommunicationType, ScheduledEmail, ScheduledNotification } from '../../types/communication';
import Avatar from '../components/misc/Avatar';
import { formatDateAndTime } from '../../utils/stringUtils';
import IconLink from '../components/links/IconLink';
import { taskService } from '../../services/taskService';
import useTasks from '../../hooks/business/useTasks';
import FeedbackCards from '../components/cards/FeedbackCards';
import EmptyScreenView from '../components/misc/EmptyScreenView';
import useIsScreenSize from '../../hooks/effects/useIsScreenSize';

export default function OverviewPage() {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const {
    application: { onboardingStep, firstName },
    connections,
  } = useSelector((state: RootState) => state);
  const role = useSelector((state: RootState) => state.application.role);
  const navigate = useNavigate();
  const isLg = useIsScreenSize('lg');

  const hasInviteFields = !!business?.inviteFields;
  const hasFiveMembers = connections.length >= 5;
  const hasAnEvent = useEvents(EventViewType.ALL_REGULAR).length > 0;

  const hasCompletedOnboarding = hasInviteFields && hasFiveMembers && hasAnEvent;

  if (!business) return <></>;

  useEffect(() => {
    if (onboardingStep !== undefined) navigate('/contacts');
  }, []);

  useEffect(() => {
    if (business.businessId !== undefined) {
      connectionService.getConnections();
      eventService.getUpcomingEvents()
        .then(() => eventService.prependPastEvents());
      communicationsService.getNotifications();
      communicationsService.getEmails();
      taskService.getAllTasksForBusiness(business.businessId);
    }
  }, [business.businessId]);

  return (
    <>
      <PageHeader
        title={t('page.overview.greeting', { name: firstName })}
        subtitle={t('page.overview.subtitle')}
        className="pb-4"
      />
      <div className="w-full flex gap-4 max-lg:flex-col">
        <div className="flex-1 flex flex-col gap-4">
          {hasCompletedOnboarding ? (
            <div className="flex gap-4 max-md:flex-col">
              <MembersCard />
              <AttendanceCard />
            </div>
          ) : (
            <SetupCard />
          )}
          {!isLg && <EventsCard />}
        </div>
        <div className="flex-1 flex flex-col gap-4">
          {!hasCompletedOnboarding && (
            <div className="flex gap-4 max-md:flex-col">
              <MembersCard />
              <AttendanceCard />
            </div>
          )}
          {isLg && <EventsCard />}
          <div className="flex gap-4 max-md:flex-col">
            <NotificationsCard />
            <EmailsCard />
          </div>
          <TasksCard />
        </div>
      </div>

      {role === 'ADMIN' && (
        <div className="mt-10">
          <div
            className="relative w-full bg-gradient-to-br from-primary-300 to-secondary p-4 h-80 rounded-md
        before:bg-gradient-to-br before:absolute before:inset-0 before:content-[''] before:from-primary-300
        before:to-secondary before:animate-blur-breathe">
            <div
              className="w-[calc(100%-0.5rem)] h-[19.5rem] left-1 top-1 absolute bg-primary-900 flex flex-col text-secondary-50 rounded-md p-4">
              <h2 className="text-secondary-50 text-2xl font-serif">🚀 A race to the top!</h2>
              <p className="text-secondary-50 w-full md:w-[40rem]">
                Show that your association is the best by inviting as many participating communities
                as possible! We offer you a free month of PRO subscription for every participating
                community that is a part of your association. Terms and conditions apply.
              </p>
              <div className="self-end flex-1 flex items-end flex-wrap gap-2 mt-4">
                <Button onClick={() => navigate('/race')} className="text-secondary-50">
                  Learn more
                </Button>
                <Button
                  className="bg-gradient-to-br from-primary-300 to-secondary-200 shadow-primary-300 shadow-sm hover:shadow-primary-300 hover:shadow-md text-primary-900">
                  Join now!
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <FeedbackCards className="px-0 mt-8 gap-4" />
    </>
  );
}

function NotificationsCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const communications = useCommunications();
  const notifications = communications?.notifications ?? [] as ScheduledNotification[];
  const sentNotificationsPercentage = notifications.length === 0 ? 0 : Math.round((notifications.filter(n => n.isSent).length / notifications.length) * 100);

  return (
    <OverviewCard icon={TbNotification} title={t('pushNotifications')} className="flex-1"
                  mainFigure={notifications.length} seeMoreText={t('seeMore')}
                  seeMoreLinkProps={{ to: '/communication' }}
                  createLinkProps={{ to: '/communication/create', state: { type: CommunicationType.NOTIFICATION } }}>
      <ProgressBar progress={sentNotificationsPercentage}
                   variant="primary-light" showText={false} />
      <p className="text-gray-500">{t('notificationsSent', { percentage: sentNotificationsPercentage })}</p>
    </OverviewCard>
  );
}

function EmailsCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const communications = useCommunications();
  const emails = communications?.emails ?? [] as ScheduledEmail[];
  const sentEmails = emails.filter(e => e.isSent).length;
  const sentEmailsPercentage = emails.length === 0 ? 0 : Math.round((sentEmails / emails.length) * 100);

  return (
    <OverviewCard icon={TbMailOpened} title={t('emailsSent')} className="flex-1"
                  mainFigure={sentEmails} seeMoreText={t('seeMore')} seeMoreLinkProps={{ to: '/communication' }}
                  createLinkProps={{ to: '/communication/create', state: { type: CommunicationType.EMAIL } }}
    >
      <ProgressBar progress={sentEmailsPercentage} variant="primary-light" showText={false} />
      <p className="text-gray-500">{t('emailsDelivered', { percentage: sentEmailsPercentage })}</p>
    </OverviewCard>
  );
}

function MembersCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const connections = useSelector((state: RootState) => state.connections);
  const fractionVerafied = connections.length > 0 ? connections.filter(isConnectionVerafied).length / connections.length : 0;

  return (
    <OverviewCard icon={TbUsers} title={t('totalMembers')} className="flex-1" mainFigure={connections.length}
                  seeMoreText={t('seeMore')} seeMoreLinkProps={{ to: '/contacts' }}>
      <ProgressBar progress={fractionVerafied * 100} variant="primary-light" showText={false} />
      <p className="text-gray-500">{t('percentageVerafied', { percentage: Math.round(fractionVerafied * 100) })}</p>
    </OverviewCard>
  );
}

function AttendanceCard(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const events = useEvents(EventViewType.ALL_REGULAR);
  const attendance = events.length > 0 ? events.flatMap(e => e.participants).filter(p => p.answer === 'YES').length / events.flatMap(e => e.participants).length : 0;

  return (
    <OverviewCard icon={TbUserCheck} title={t('attendance')} className="flex-1"
                  seeMoreText={t('seeMore')} seeMoreLinkProps={{ to: '/events/report' }}>
      <ResponsiveContainer width="100%" height={200} maxHeight={200} className="mt-[-20px] mb-[-100px]">
        <PieChart>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={[{ name: 'Attended', value: attendance, fill: COLOR_PRIMARY_300 }, {
              name: 'Not attended',
              value: 1 - attendance,
              fill: COLOR_SECONDARY_200,
            }]}
            strokeWidth={0}
            cx="50%"
            cy="50%"
            innerRadius="50%"
          >
            <Label
              value={`${Math.round(attendance * 100)}%`}
              position="center"
              fontSize="26"
              dy={-20}
              fill={COLOR_PRIMARY_900}
            />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </OverviewCard>
  );
}

function EventsCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const events = useEvents(EventViewType.REGULAR);

  return (
    <OverviewCard icon={TbCalendar} title={t('upcomingEvents')} totalCount={events.length} className="flex-1 max-h-fit"
                  createLinkProps={{ to: '/events/create' }} seeMoreText={t('seeAllEvents')}
                  seeMoreLinkProps={{ to: '/events' }}>
      <div className="flex flex-col gap-2">
        {events.length === 0 && <EmptyScreenView title="" subtitle={t('noEvents')} />}
        {events.slice(0, 3).map(event => <EventItem event={event} key={event.id} />)}
      </div>
    </OverviewCard>
  );
}

function EventItem({ event }: { event: Event }) {
  const { title, picture, startTime, participants } = event;

  return (
    <div className="flex items-center gap-2 bg-secondary-200 rounded-xl p-3 justify-between">
      <div className="flex items-center gap-2">
        <Avatar src={picture} alias={title.toUpperCase().split(' ').slice(0, 2).join('+')} className="text-xl" />
        <div>
          <h4>{title}</h4>
          <p className="text-sm text-gray-500">{formatDateAndTime(startTime)}</p>
        </div>
      </div>
      <div className="flex items-center gap-1">
        <TbUserCheck className="text-primary-300 text-2xl" />
        <p className="text-primary-300">{participants.filter(p => p.answer === 'YES').length}</p>
        <IconLink icon={TbChevronRight} className="text-2xl ml-2" to="/events/details" state={{ eventId: event.id }} />
      </div>
    </div>
  );
}

function TasksCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const tasks = useTasks();

  return (
    <OverviewCard icon={TbCircleCheck} title={t('toDoList')} className="flex-1 max-h-fit" seeMoreText={t('seeAllTasks')}
                  seeMoreLinkProps={{ to: '/lists/task' }} totalCount={tasks.length}
                  createLinkProps={{ to: '/lists/task', state: { createOpen: true } }}>
      <div className="flex flex-col">
        {tasks.length === 0 && <EmptyScreenView title="" subtitle={t('noTasks')} />}
        {tasks.slice(0, 5).map((task) => (
          <div className="py-3 border-b border-b-gray-400 last-of-type:border-none">
            <div className="flex items-center gap-4">
              <button
                type="button"
                onClick={() => taskService.toggleDone(task)}
                className="justify-centre flex items-center text-sm text-primary-300">
                <div className="flex items-center justify-center gap-2">
                  {task.done ? <FaCircleCheck size={24} /> : <FaRegCircle size={24} />}
                </div>
              </button>
              <h4
                className="font-semibold whitespace-nowrap overflow-x-hidden overflow-ellipsis">{task.description}</h4>
            </div>
            <p
              className="pl-10 pt-1 text-sm text-gray-500">{task.dueDate && formatDateAndTime(new Date(task.dueDate))}</p>
          </div>
        ))}
      </div>
    </OverviewCard>
  );
}

function SetupCard() {
  const { t } = useTranslation('', { keyPrefix: 'page.overview' });
  const business = useSelectedBusiness();
  const connections = useSelector((state: RootState) => state.connections);

  const hasInviteFields = !!business?.inviteFields;
  const hasFiveMembers = connections.length >= 5;
  const hasAnEvent = useEvents(EventViewType.ALL_REGULAR).length > 0;

  return (
    <OverviewCard icon={TbSettingsCheck} title={t('finishSetup')} className="flex-1 max-h-fit"
                  totalCount={[hasAnEvent, hasFiveMembers, hasInviteFields].filter(x => !x).length}>
      <div className="flex flex-col gap-2">
        {!hasInviteFields && (
          <OnboardingItem title={t('inviteFieldsMissingTitle')} subtitle={t('inviteFieldsMissingSubtitle')}
                          linkText={t('start')} linkProps={{ to: '/invite-link', state: { view: 'SETUP' } }} />
        )}
        {!hasFiveMembers && (
          <OnboardingItem title={t('notEnoughMembersTitle')} subtitle={t('notEnoughMembersSubtitle')}
                          linkText={t('continue')} linkProps={{ to: '/contacts' }} />
        )}
        {!hasAnEvent && (
          <OnboardingItem title={t('eventMissingTitle')} subtitle={t('eventMissingSubtitle')}
                          linkText={t('start')} linkProps={{ to: '/events/create' }} />
        )}
      </div>
    </OverviewCard>
  );
}

function OnboardingItem({ title, subtitle, linkText, linkProps: { className: linkClassName, ...linkProps } }: {
  title: string;
  subtitle: string;
  linkText: string;
  linkProps: Omit<LinkProps, 'children'>;
}) {
  return (
    <div className="flex items-center justify-between gap-4 p-4 rounded-xl bg-secondary-50">
      <div className="flex-1">
        <h4 className="font-semibold">{title}</h4>
        <p className="text-sm text-gray-500">{subtitle}</p>
      </div>
      <Link className={twMerge('hover:opacity-80 bg-primary-900 text-secondary-50 rounded-xl p-2', linkClassName)} {...linkProps}>{linkText}</Link>
    </div>
  );
}
