import React, { useEffect, useState } from 'react';
import { FaShareNodes } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../layouts/ModalLayout';
import InvitationLink from '../misc/InvitationLink';
import localContactService from '../../../services/localContactService';
import IconBadge from '../icons/IconBadge';

interface InvitationLinkModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  connectionId?: string;
}

export default function InvitationLinkModal({
  open,
  setOpen,
  connectionId,
}: InvitationLinkModalProps): JSX.Element {
  const [link, setLink] = useState<string>();
  const { t } = useTranslation();


  useEffect(() => {
    if (!open) return;
    if (!link && connectionId) localContactService.generateFriendlyId(+connectionId).then(setLink);
  }, [open]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="z-50 mx-6 flex w-full min-w-[450px] max-w-[600px] flex-col rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <div className="mb-6 flex w-full items-center">
        <IconBadge icon={FaShareNodes} />

        <div className="ml-4 flex w-full flex-col">
          <h1 className="font-serif text-2xl">
            {t('component.modal.localContactInvitationLink.title')}
          </h1>
          <p>{t('component.modal.localContactInvitationLink.subtitle')}</p>
        </div>
      </div>

      <InvitationLink link={link} />
    </ModalLayout>
  );
}
