import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Task } from '../../services/model/taskService.model';

export interface TasksState {
  taskMap: Record<number, Task[]>;
}

const initialState: TasksState = {
  taskMap: {},
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    addTask: (state, action: PayloadAction<{ businessId: number; task: Task }>): void => {
      const { businessId, task } = action.payload;
      if (!state.taskMap[businessId]) {
        state.taskMap[businessId] = [];
      }
      state.taskMap[businessId].push(task);
    },

    setTasks(state, action: PayloadAction<{ businessId: number; tasks: Task[] }>): void {
      const { businessId, tasks } = action.payload;
      if (!state.taskMap[businessId]) {
        state.taskMap[businessId] = [];
      }
      state.taskMap[businessId] = tasks;
    },

    removeTask(state, action: PayloadAction<{ businessId: number; taskId: number }>): void {
      const { businessId, taskId } = action.payload;
      state.taskMap[businessId] = state.taskMap[businessId].filter(
        (task) => task.taskId !== taskId,
      );
    },

    updateTask(state, action: PayloadAction<{ businessId: number; task: Task }>): void {
      const { businessId, task } = action.payload;
      state.taskMap[businessId] = state.taskMap[businessId].map((e) =>
        e.taskId === task.taskId ? task : e,
      );
      if (!state.taskMap[businessId].find((e) => e.taskId === task.taskId))
        state.taskMap[businessId].push(task);
    },

    toggleDone(state, action: PayloadAction<{ businessId: number; taskId: number }>): void {
      const { businessId, taskId } = action.payload;
      state.taskMap[businessId] = state.taskMap[businessId].map((e) =>
        e.taskId === taskId ? { ...e, done: !e.done } : e,
      );
    },
  },
});

export const { addTask, setTasks, removeTask, toggleDone, updateTask } =
  taskSlice.actions;
const taskReducer = taskSlice.reducer;
export default taskReducer;
