import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { enGB, nl } from 'date-fns/locale';
import { TbEdit, TbTrash } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import communicationsService from '../../../services/communicationsService';
import useCommunications from '../../../hooks/business/useCommunications';
import {
  CommunicationType,
  PostcardOrder,
  ScheduledEmail,
  ScheduledNotification,
} from '../../../types/communication';
import CommunicationBadge from '../badges/CommunicationBadge';
import { capitalizeFirstLetter, formatDateAndTime } from '../../../utils/stringUtils';
import { ConnectionType } from '../../../redux/slices/connectionsSlice';
import { getProfilePictureUrl } from '../../../utils/connectionUtils';
import Avatar from '../misc/Avatar';
import WarningModal from '../modals/WarningModal';
import useLanguage from '../../../hooks/account/useLanguage';
import Language from '../../../types/language';
import {
  BaseTable,
  BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
  BaseTablePagination,
} from './BaseTable';
import { ActionMenu, ActionMenuButton } from '../menus/ActionMenu';
import SearchBar from '../forms/SearchBar';
import { usePagination } from '../../../hooks/effects/usePagination';
import DatePicker from '../forms/DatePicker';
import dateUtils from '../../../utils/dateUtils';
import { withinDateBounds } from '../../../utils/validationUtils';

export default function CommunicationsTable() {
  const { t } = useTranslation();
  const { emails, notifications, postcards } = useCommunications() ?? {};
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(
    dateUtils.setHoursToZero(new Date()),
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    dateUtils.setHoursToZero(new Date(Date.now() + 1000 * 60 * 60 * 24 * 30 * 3)),
  ); // 3 months

  const communications = [...(emails ?? []), ...(notifications ?? []), ...(postcards ?? [])].sort(
    (a, b) => new Date(a.sendDate).getTime() - new Date(b.sendDate).getTime(),
  );

  const filteredCommunications = communications.filter((c) => {
    if (!withinDateBounds(c, startDate, endDate)) return false;

    if (search === '') return true;
    if ('emailId' in c) {
      return (
        c.body.toLowerCase().includes(search.toLowerCase()) ||
        c.title.toLowerCase().includes(search.toLowerCase()) ||
        formatDateAndTime(new Date(c.sendDate)).toLowerCase().includes(search.toLowerCase())
      );
    }

    return (
      c.message.toLowerCase().includes(search.toLowerCase()) ||
      formatDateAndTime(new Date(c.sendDate)).toLowerCase().includes(search.toLowerCase())
    );
  });

  const pagination = usePagination(filteredCommunications);

  return (
    <>
      <div className="flex items-center justify-between">
        <SearchBar
          inputProps={{ placeholder: t('page.communicationOverview.search') }}
          search={search}
          setSearch={setSearch}
          className="md:w-80 lg:w-96"
        />
        <div className="flex items-center gap-1">
          <DatePicker
            customFormat="[From:] DD-MM-YYYY"
            date={startDate}
            setDate={setStartDate}
            includeTime={false}
          />
          <DatePicker
            customFormat="[To:] DD-MM-YYYY"
            date={endDate}
            setDate={setEndDate}
            includeTime={false}
          />
        </div>
      </div>
      <BaseTable>
        <BaseTableHeader>
          <BaseTableHeaderRow>
            <th>{t('page.communicationOverview.table.column.type')}</th>
            <th>{t('page.communicationOverview.table.column.description')}</th>
            <th>{t('page.communicationOverview.table.column.date')}</th>
            <th>{t('page.communicationOverview.table.column.receivers')}</th>
            <th>{t('page.communicationOverview.table.column.status')}</th>
            <th>{t('page.communicationOverview.table.column.actions')}</th>
          </BaseTableHeaderRow>
        </BaseTableHeader>
        <div />
        {pagination.items.map((c, i) => (
          <TableRow key={i} communication={c} />
        ))}
        <BaseTablePagination pagination={pagination} />
      </BaseTable>
    </>
  );
}

interface TableRowProps {
  communication: ScheduledEmail | ScheduledNotification | PostcardOrder;
}

function TableRow({ communication }: TableRowProps) {
  const [pictures, setPictures] = useState<string[]>([]);
  const { t } = useTranslation();
  const lang = useLanguage();
  const locale = lang === Language.NL ? nl : enGB;

  const type = getType(communication);

  const description =
    type === CommunicationType.EMAIL
      ? (communication as ScheduledEmail).body
      : (communication as ScheduledNotification | PostcardOrder).message;

  useEffect(() => {
    const fetchPictures = async () => {
      const ps = await Promise.all(
        [
          ...communication.connectionIds.map((id) => ({
            id: id.toString(),
            type: ConnectionType.B2C_CONNECTION,
          })),
          ...((communication as ScheduledEmail | PostcardOrder).localContactIds ?? []).map(
            (id) => ({
              id: id.toString(),
              type: ConnectionType.LOCAL_CONTACT,
            }),
          ),
        ].map(async (id) => {
          const picture = await getProfilePictureUrl(id).catch(() => '');
          return picture;
        }),
      );
      setPictures(ps.filter(Boolean).splice(0, 3));
    };

    fetchPictures();
  }, []);

  const numInvited =
    communication.connectionIds.length +
    ((communication as ScheduledEmail).localContactIds?.length ?? 0);
  const isSent =
    'isSent' in communication ? communication.isSent : communication.sendDate < new Date();
  const sendDate =
    type === CommunicationType.POSTCARD
      ? addDays(new Date(communication.sendDate), 1)
      : communication.sendDate;

  return (
    <BaseTableDataRow>
      <td>
        <div className="flex w-full items-center gap-2 px-2 py-4 font-semibold">
          <CommunicationBadge type={type} /> {capitalizeFirstLetter(type)}
        </div>
      </td>
      <td>
        <p className="line-clamp-3 text-ellipsis text-[15px] font-medium leading-[18px]">
          {description}
        </p>
      </td>

      <td>
        <p className="text-[15px] font-semibold">
          {format(sendDate, `dd MMM${type !== CommunicationType.POSTCARD ? ' HH:mm' : ''}`, {
            locale,
          })}
        </p>
      </td>
      <td>
        <div className="flex w-full items-center">
          {pictures.map((p, i) => (
            <Avatar
              key={i}
              className={`h-10 w-10 rounded-full ${i > 0 && '-ml-4'}`}
              alias=""
              src={p}
            />
          ))}
          <p className="ml-2 text-[15px] font-medium leading-[18px]">
            {pictures.length ? (
              pictures.length !== numInvited && (
                <>
                  +<br />
                  {numInvited - pictures.length} {t('page.communicationOverview.table.more')}
                </>
              )
            ) : (
              <>
                {numInvited} {t('page.communicationOverview.table.invited')}
              </>
            )}
          </p>
        </div>
      </td>
      <td>
        <div
          className={classNames(
            'flex h-fit w-fit items-center justify-center rounded-[10px] px-2 py-2 font-medium text-secondary-50',
            {
              'bg-primary-300': isSent,
              'bg-secondary': !isSent,
            },
          )}>
          {t(`page.communicationOverview.table.${isSent ? 'sent' : 'scheduled'}`)}
        </div>
      </td>
      <td className="my-auto ml-10 h-fit w-fit">
        <CommunicationOptions communication={communication} />
      </td>
    </BaseTableDataRow>
  );
}

interface CommunicationOptionsProps {
  communication: ScheduledEmail | ScheduledNotification | PostcardOrder;
}

function CommunicationOptions({ communication }: CommunicationOptionsProps) {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isSent =
    'isSent' in communication ? communication.isSent : communication.sendDate < new Date();

  const handleEdit = () =>
    navigate('/communication/edit', {
      state: {
        communication,
        type: getType(communication),
      },
    });

  const handleDelete = () => {
    if ((communication as ScheduledEmail).emailId) {
      communicationsService.deleteScheduledEmail((communication as ScheduledEmail).emailId);
    } else
      communicationsService.deleteScheduledNotification(
        (communication as ScheduledNotification).notificationId,
      );
  };

  return (
    <>
      <ActionMenu>
        {!isSent && (
          <ActionMenuButton
            icon={TbEdit}
            text={t(`page.communicationOverview.table.edit`)}
            onClick={handleEdit}
          />
        )}
        {!(communication as PostcardOrder).orderId && (
          <ActionMenuButton
            className="text-danger"
            icon={TbTrash}
            text={t(`page.communicationOverview.table.delete`)}
            onClick={() => setDeleteModalOpen(true)}
          />
        )}
      </ActionMenu>
      <WarningModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        title={t(`page.communicationOverview.table.deleteModal.title`)}
        description={t(`page.communicationOverview.table.deleteModal.description`)}
        onClose={handleDelete}
        saveClassName="bg-danger text-secondary-50"
        button={t(`page.communicationOverview.table.delete`)}
      />
    </>
  );
}

function getType(communication: ScheduledEmail | ScheduledNotification | PostcardOrder) {
  if ('emailId' in communication) return CommunicationType.EMAIL;
  if ('notificationId' in communication) return CommunicationType.NOTIFICATION;
  return CommunicationType.POSTCARD;
}
