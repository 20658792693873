import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

export default function EmptyScreenView({ title, subtitle, containerClassName }: { title?: string, subtitle?: string, containerClassName?: string }): JSX.Element {
  const { t } = useTranslation('');

  return (
    <section className={twMerge('mt-8 flex flex-col items-center justify-center w-full gap-4', containerClassName)}>
      <img src="/assets/figures/mess.svg" alt="vera mess" className="w-1/4 max-md:w-1/2" />
      <h2 className="font-serif text-center text-2xl mt-2">{title ?? t('general.emptyScreenTitle')}</h2>
      <p className="text-center w-1/2 max-md:w-[80%] text-gray-500">{subtitle ?? t('general.emptyScreenSubtitle')}</p>
    </section>
  );
}
