import React from 'react';
import { PropsOf } from '@headlessui/react/dist/types';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';

export default function IconLink({ className, icon: Icon, ...props }: {
  icon: IconType
} & Omit<PropsOf<typeof Link>, 'children'>): JSX.Element {
  return (
    <Link className={twMerge('hover:opacity-80 text-primary-900 text-lg', className)} {...props}>
      <Icon />
    </Link>
  );
}
