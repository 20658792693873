import React, { useEffect, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoDuplicate, IoShareSocial } from 'react-icons/io5';
import { format } from 'date-fns';
import { FiCalendar, FiMapPin } from 'react-icons/fi';
import { FaBell, FaCheck, FaChevronDown, FaThumbtack, FaUserMinus, FaXmark } from 'react-icons/fa6';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AiOutlineClose } from 'react-icons/ai';
import { HiTrash } from 'react-icons/hi';
import {
  FaCheckCircle,
  FaCircle,
  FaPencilAlt,
  FaRegClock,
  FaSave,
  FaUserEdit,
} from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi2';
import { BiSolidDownload } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { TbBell, TbCheck, TbUserMinus } from 'react-icons/tb';
import Button from '../../components/buttons/Button';
import useEvents from '../../../hooks/business/useEvents';
import IconBadge from '../../components/icons/IconBadge';
import {
  DateOption,
  Event,
  EventCustomField,
  EventCustomFieldResponse,
  EventParticipant,
  EventParticipationAnswer,
  EventViewType,
  RecurrenceEditType,
  UpdateEventModel,
} from '../../../types/event';
import Avatar from '../../components/misc/Avatar';
import WarningModal, { SimpleWarningModalProps } from '../../components/modals/WarningModal';
import eventService from '../../../services/eventService';
import EventDatesForm from '../../components/forms/EventDatesForm';
import AutoCompleteLocationInput from '../../components/forms/AutoCompleteLocationInput';
import { eventParticipantAnswerIcons } from '../../../utils/iconUtils';
import Icon from '../../components/icons/Icon';
import ProgressBar from '../../components/misc/ProgressBar';
import ModalLayout from '../../components/layouts/ModalLayout';
import eventUtils from '../../../utils/eventUtils';
import AttendanceListModal from '../../components/modals/AttendanceListModal';
import ParticipationBadge from '../../components/badges/ParticipationBadge';
import ApiImage from '../../components/misc/ApiImage';
import { AdditionalEventDataMap } from '../../../types/misc';
import { CustomFieldType } from '../../../services/model/inviteService.model';
import BusinessImageInput from '../../components/forms/BusinessImageInput';
import dateUtils from '../../../utils/dateUtils';
import Slider from '../../components/misc/Slider';
import { formatUrl, isValidHttpUrl } from '../../../utils/validationUtils';
import { getUserId } from '../../../services/httpService';
import { RootState } from '../../../redux/reducers';
import { Connection } from '../../../redux/slices/connectionsSlice';
import useBusinessEffect from '../../../hooks/effects/useBusinessEffect';
import connectionService from '../../../services/connectionService';
import SearchBar from '../../components/forms/SearchBar';
import { searchFilter } from '../../../utils/filterUtils';
import Input from '../../components/misc/Input';
import EventShareModal from '../../components/modals/EventShareModal';
import ExtraOption from '../../components/misc/ExtraOption';
import { ActionMenu, ActionMenuButton } from '../../components/menus/ActionMenu';
import WithTooltip from '../../components/misc/WithTooltip';
import ParticipantsPicker from '../../components/misc/ParticipantsPicker';

interface EventDetailsPageState {
  eventId?: number;
}

interface EventDetailsPageProps {
  type: EventViewType;
}

enum MAIN_VIEWS {
  DETAILS = 'DETAILS',
  EDIT = 'EDIT',
}

enum SECONDARY_VIEWS {
  PARTICIPANTS = 'PARTICIPANTS',
  EDIT_PARTICIPANTS = 'EDIT_PARTICIPANTS',
}

export default function EventDetailsPage({ type }: EventDetailsPageProps): JSX.Element | null {
  const { eventId } = useLocation().state as EventDetailsPageState;
  const navigate = useNavigate();
  const event = useEvents().find((e) => e.id === eventId);

  const [view, setView] = useState<MAIN_VIEWS>(MAIN_VIEWS.DETAILS);
  const [recurrenceEditType, setRecurrenceEditType] = useState<RecurrenceEditType | undefined>();
  const [secondaryView, setSecondaryView] = useState<SECONDARY_VIEWS>(SECONDARY_VIEWS.PARTICIPANTS);

  const returnUrl = `/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}`;

  useEffect(() => {
    if (!eventId) navigate(returnUrl);
    else eventService.fetchEvent(eventId).catch(() => navigate(returnUrl));
  }, []);

  useEffect(() => {
    if (!event) return;
    if (
      (type === EventViewType.DATE_PICKER && !event.isDatePicker) ||
      (type === EventViewType.REGULAR && event.isDatePicker)
    )
      navigate(returnUrl);
  }, [event]);

  if (!event) return null;

  return (
    <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
      <div className="flex w-full flex-1 flex-col gap-4">
        {view === MAIN_VIEWS.DETAILS ? (
          <DetailsPanel
            event={event}
            setView={setView}
            type={type}
            setRecurrenceEditType={setRecurrenceEditType}
          />
        ) : (
          <EditEventPanel
            event={event}
            setView={setView}
            type={type}
            recurrenceEditType={recurrenceEditType}
          />
        )}
        {type === EventViewType.DATE_PICKER && <DateOptionsPanel options={event.dateOptions} />}
        <PollsPanel polls={event.customFields} />
      </div>

      {secondaryView === SECONDARY_VIEWS.PARTICIPANTS ? (
        type === EventViewType.REGULAR ? (
          <EventParticipantsPanel event={event} setView={setSecondaryView} />
        ) : (
          <DatePickerResponsesOverviewPanel event={event} setView={setSecondaryView} />
        )
      ) : (
        <EventEditParticipantsPanel event={event} setView={setSecondaryView} />
      )}
    </div>
  );
}

// EVENT
interface DetailsPanelProps {
  event: Event;
  setView: (view: MAIN_VIEWS) => void;
  type: EventViewType;
  setRecurrenceEditType: (type: RecurrenceEditType) => void;
}
function DetailsPanel({ event, setView, type, setRecurrenceEditType }: DetailsPanelProps) {
  const {
    picture,
    title,
    description,
    startTime,
    endTime,
    deadline,
    location,
    locationUrl,
    onlineLocation,
    id,
    maximumAttendees,
  } = event;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [editRecurringModalOpen, setEditRecurringModalOpen] = useState(false);

  const handleDelete = (deleteRecurrences: RecurrenceEditType = RecurrenceEditType.SINGLE) => {
    eventService.deleteEvent(id, deleteRecurrences).then(() => {
      navigate('/events');
    });
  };

  return (
    <div className="flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-4">
      <div className="flex items-center justify-between">
        <h1 className="font-serif text-[28px] leading-[28px]">{title}</h1>
        <div className="flex items-center gap-4">
          <WithTooltip tooltip={t('tooltip.duplicate')}>
            <Link
              to={`/events${type === EventViewType.DATE_PICKER ? '/date-picker' : ''}/create`}
              state={{ event: eventUtils.fromEventToCreateEventModel(event) }}>
              <Button className="flex-shrink-0 rounded-full p-0" variant="tertiary">
                <IoDuplicate className="size-7" />
              </Button>
            </Link>
          </WithTooltip>
          <WithTooltip tooltip={t('tooltip.share')}>
            <Button
              className="flex-shrink-0 rounded-full p-0"
              variant="tertiary"
              onClick={() => setShareModalOpen(true)}>
              <IoShareSocial className="size-7" />
            </Button>
          </WithTooltip>
          <WithTooltip tooltip={t('tooltip.edit')}>
            <Button
              className="flex-shrink-0 rounded-full p-3"
              variant="primary"
              onClick={() => {
                if (event.isRecurring) {
                  setEditRecurringModalOpen(true);
                  return;
                }

                setView(MAIN_VIEWS.EDIT);
              }}>
              <FaPencilAlt className="size-5" />
            </Button>
          </WithTooltip>
          <WithTooltip tooltip={t('tooltip.delete')}>
            <Button
              className="flex-shrink-0 rounded-full p-3"
              variant="secondary"
              onClick={() => setModalOpen(true)}>
              <HiTrash className="size-5" />
            </Button>
          </WithTooltip>
        </div>
      </div>
      <div className="w-full border-b border-primary-300" />
      {picture && <ApiImage path={picture} className="h-[100px] w-[100px] rounded-[20px]" />}
      <div className="flex w-full flex-col gap-2">
        <h2 className="font-serif text-[18px] leading-[24px]">{t('general.information')}</h2>
        {type === EventViewType.REGULAR ? (
          <>
            <div className="flex items-center gap-2">
              <IconBadge icon={FiCalendar} className="rounded-[6px]" />
              <p className="text-sm">
                {`${format(startTime, 'dd-MM-yyyy')}${
                  endTime ? ` -> ${format(startTime, 'dd-MM-yyyy')}` : ''
                }`}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <IconBadge icon={FaRegClock} className="rounded-[6px]" />
              <p className="text-sm">{eventUtils.formatDate(event, 'time')}</p>
            </div>
          </>
        ) : (
          <>
            <div className="flex items-center gap-2">
              <IconBadge icon={FiCalendar} className="rounded-[6px]" />
              <p className="text-sm">
                {`${format(event.dateOptions[0]?.startTime, 'dd-MM-yyyy')} + ${
                  event.dateOptions.length
                } ${t('general.options')}`}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <IconBadge icon={HiUserGroup} className="rounded-[6px]" />
              <p className="text-sm">{`${
                event.participants.filter((p) =>
                  p.answers?.some((a) => a.answer !== EventParticipationAnswer.PENDING),
                ).length
              } / ${event.memberCount}`}</p>
            </div>
          </>
        )}
        {location && !onlineLocation && (
          <a
            href={locationUrl}
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-2 hover:underline">
            <IconBadge icon={FiMapPin} className="rounded-[6px]" />
            <p className="text-sm">{location}</p>
          </a>
        )}

        {locationUrl && onlineLocation && (
          <a
            href={locationUrl}
            target="_blank"
            rel="noreferrer"
            className="flex items-center gap-2 hover:underline">
            <IconBadge icon={FiMapPin} className="rounded-[6px]" />
            <p className="text-sm">({t('page.eventDetails.onlineLocation')})</p>
          </a>
        )}
        {maximumAttendees && (
          <div className="flex items-center gap-2">
            <IconBadge icon={HiUserGroup} className="rounded-[6px]" />
            <p className="text-sm">{`Max. ${maximumAttendees} ${t('general.attendees')}`}</p>
          </div>
        )}
        {deadline && (
          <div className="flex items-center gap-2">
            <IconBadge icon={FiCalendar} className="rounded-[6px]" />
            <p className="text-sm">{`Deadline: ${format(deadline, 'dd-MM-yyyy')}`}</p>
          </div>
        )}
        {description && (
          <div className="flex w-full flex-col">
            <h2 className="font-serif text-[18px] leading-[24px]">{t('general.description')}</h2>
            <p className="text-sm">{description}</p>
          </div>
        )}
      </div>
      {type === EventViewType.DATE_PICKER && (
        <>
          <div className="w-full border-b border-primary-300" />
          <PickDateButton event={event} />
        </>
      )}
      <WarningModal
        title={t('warning.deleteEvent.title')}
        description={t('warning.deleteEvent.description')}
        button={t('general.delete')}
        saveClassName="bg-danger text-secondary-50"
        open={modalOpen && !event.isRecurring}
        setOpen={setModalOpen}
        onClose={handleDelete}
      />

      <ModalLayout
        open={editRecurringModalOpen}
        setOpen={setEditRecurringModalOpen}
        closeButton
        className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col justify-center gap-4 rounded-[20px] bg-secondary-50 px-6 py-8 lg:px-12">
        <div className="mb-4 flex flex-col gap-2">
          <h3 className="font-serif text-xl font-semibold">
            {t('warning.editRecurringEvent.title')}
          </h3>
          <p className="text">{t('warning.editRecurringEvent.description')}</p>
        </div>
        {Object.values(RecurrenceEditType).map((r) => (
          <Button
            variant="primary"
            className="w-full justify-center"
            onClick={() => {
              setRecurrenceEditType(r);
              setView(MAIN_VIEWS.EDIT);
            }}>
            {t(`warning.eventRecurrenceEditTypes.${r.toLowerCase()}`)}
          </Button>
        ))}
        <Button
          className="w-full justify-center underline"
          onClick={() => setEditRecurringModalOpen(false)}>
          {t('general.cancel')}
        </Button>
      </ModalLayout>

      <ModalLayout
        open={modalOpen && event.isRecurring}
        setOpen={setModalOpen}
        closeButton
        className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col justify-center gap-4 rounded-[20px] bg-secondary-50 px-6 py-8 lg:px-12">
        <div className="mb-4 flex flex-col gap-2">
          <h3 className="font-serif text-xl font-semibold">
            {t('warning.deleteRecurringEvent.title')}
          </h3>
          <p className="text">{t('warning.deleteRecurringEvent.description')}</p>
        </div>
        {Object.values(RecurrenceEditType).map((r) => (
          <Button
            variant="primary"
            className="w-full justify-center"
            onClick={() => handleDelete(r)}>
            {t(`warning.eventRecurrenceEditTypes.${r.toLowerCase()}`)}
          </Button>
        ))}
        <Button className="w-full justify-center underline" onClick={() => setModalOpen(false)}>
          {t('general.cancel')}
        </Button>
      </ModalLayout>

      <EventShareModal open={shareModalOpen} setOpen={setShareModalOpen} event={event} />
    </div>
  );
}

interface PickDateButtonProps {
  event: Event;
}

function PickDateButton({ event }: PickDateButtonProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useLayoutEffect(() => {
    if (modalOpen) setSelectedIds([]);
  }, [modalOpen]);

  const handleClick = (id: number) => {
    if (selectedIds.includes(id)) setSelectedIds(selectedIds.filter((i) => i !== id));
    else setSelectedIds([...selectedIds, id]);
  };

  const handleSubmit = () => {
    eventService.pickDateOptions(event.id, selectedIds).then(() => {
      setModalOpen(false);
      navigate('/events');
    });
  };

  return (
    <div
      onClick={() => setModalOpen(true)}
      className="flex w-full cursor-pointer items-center justify-between rounded-[20px] bg-primary-300 p-4 hover:bg-opacity-80">
      <div className="flex gap-4">
        <img
          src={`${process.env.PUBLIC_URL}/assets/figures/question.svg`}
          alt="Vera Question"
          className="w-[50px]"
        />
        <div className="my-auto flex w-full flex-col">
          <h2 className="text-[18px] leading-[24px]">{t('page.eventDetails.pickDate.title')}</h2>
          <p className="text-sm">{t('page.eventDetails.pickDate.subtitle')} </p>
        </div>

        <div className="flex h-fit w-fit flex-shrink-0 self-center rounded-full bg-primary p-3">
          <FaThumbtack className="size-5 text-secondary-50" />
        </div>
      </div>

      <ModalLayout
        open={modalOpen}
        setOpen={setModalOpen}
        closeButton
        className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col justify-center gap-6 rounded-[20px] bg-secondary-50 px-6 py-8 lg:px-12">
        {eventUtils.sortDateOptions(event.dateOptions).map((o) => (
          <div className="flex items-center gap-4">
            <DateOptionResult option={o} />
            <Button
              onClick={() => handleClick(o.id)}
              className={`h-10 w-10 justify-center rounded-[6px] border-2 border-primary-300 hover:border-opacity-80 ${
                selectedIds.includes(o.id)
                  ? 'bg-primary-300 text-primary-900 hover:bg-opacity-80'
                  : 'text-primary-300 hover:text-opacity-80'
              }`}>
              <FaThumbtack className="size-5 flex-shrink-0" />
            </Button>
          </div>
        ))}
        <Button
          className="w-full justify-center"
          variant="primary"
          disabled={!selectedIds.length}
          onClick={handleSubmit}>
          {t('page.eventDetails.pickDate.button')}
        </Button>
      </ModalLayout>
    </div>
  );
}

interface EditEventPanelProps {
  event: Event;
  setView: (view: MAIN_VIEWS) => void;
  type: EventViewType;
  recurrenceEditType?: RecurrenceEditType;
}

const NOT_ALLOWED_FIELDS = [
  'isCancelled',
  'additionalData',
  'businessId',
  'memberCount',
  'ownerId',
  'role',
  'isRecurring',
  'recurrenceBatchId',
  'frequency',
  'isDatePicker',
  'participants',
  'dateOptions',
  'customFields',
  'organizer',
  'notificationSettings',
];

function EditEventPanel({
  event: originalEvent,
  setView,
  type,
  recurrenceEditType,
}: EditEventPanelProps) {
  const [options, setOptions] = useState<AdditionalEventDataMap>({
    picture: !!originalEvent.picture,
    location: !!originalEvent.location,
    capacity: !!originalEvent.maximumAttendees,
    deadline: !!originalEvent.deadline,
    notificationSettings: !!originalEvent.notificationSettings,
    poll: !!originalEvent.customFields.length,
  });
  const [event, setEvent] = useState<Event>(originalEvent);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const { title, description, location, locationUrl, onlineLocation, maximumAttendees, picture } =
    event;

  const { t } = useTranslation();

  useEffect(() => {
    setErrors({});
  }, [event]);

  const validate = (): boolean => {
    const foundErrors: Record<string, string> = {};

    if (!title) {
      foundErrors.title = t('page.createEvent.fields.title.error');
    }

    if (!event.startTime) {
      foundErrors.startTime = t('page.createEvent.fields.startDate.required');
    }

    if (event.endTime && event.endTime < event.startTime) {
      foundErrors.endTime = t('page.createEvent.fields.endDate.invalid');
    }

    if (
      event.customFields.some(
        (f) =>
          !f.label || (f.type === CustomFieldType.MULTIPLE_CHOICE && f.options.some((o) => !o)),
      )
    ) {
      foundErrors.customFields = 'true';
    }

    if (event.deadline && event.deadline < new Date()) {
      foundErrors.deadline = t('page.createEvent.fields.deadline.invalid');
    }

    if (event.deadline && event.startTime && event.deadline > event.startTime) {
      foundErrors.deadline = t('page.createEvent.fields.deadline.beforeStartDate');
    }

    if (onlineLocation && locationUrl) {
      if (!isValidHttpUrl(locationUrl)) {
        foundErrors.locationUrl = t('page.createEvent.fields.location.online.error');
      }
    }

    if (Object.keys(foundErrors).length) {
      setErrors(foundErrors);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validate()) {
      // toast.error(t('toast.error.invalidEvent'));
      return;
    }

    const data = _.omit(event, NOT_ALLOWED_FIELDS);
    data.locationUrl = event.locationUrl ?? undefined;

    if (onlineLocation && locationUrl) {
      const formattedUrl = formatUrl(locationUrl);
      if (formattedUrl !== locationUrl) data.locationUrl = formattedUrl;
    }

    data.endTime = event.endTime ?? undefined;
    eventService
      .updateEvent(originalEvent.id, data as UpdateEventModel, recurrenceEditType)
      .then(() => {
        setView(MAIN_VIEWS.DETAILS);
        toast.success(t('toast.success.eventUpdated'));
      });
  };

  return (
    <div className="flex h-fit w-full flex-col gap-4 rounded-[20px] bg-secondary-200 p-4">
      <div className="flex items-center justify-between">
        <h1 className="font-serif text-[28px] leading-[28px]">{originalEvent.title}</h1>
        <div className="flex gap-4">
          <Button
            className="flex-shrink-0 rounded-full p-3"
            variant="primary"
            disabled={_.isEqual(event, originalEvent) || Object.keys(errors).length > 0}
            onClick={handleSubmit}>
            <FaSave className="size-5" />
          </Button>
          <Button
            className="flex-shrink-0 rounded-full p-3"
            variant="secondary"
            onClick={() => setView(MAIN_VIEWS.DETAILS)}>
            <AiOutlineClose className="size-5" />
          </Button>
        </div>
      </div>
      <div className="w-full border-b border-primary-300" />
      <div className="flex flex-col gap-4 rounded-[20px] border border-primary-300 bg-secondary-50 p-4">
        <BusinessImageInput
          image={picture}
          className="h-[100px] w-[100px]"
          setImage={(p) => setEvent({ ...event, picture: p })}
        />
        <Input
          label={t('general.title')}
          id="title"
          type="text"
          className="w-full"
          value={title}
          onChange={(e) => setEvent({ ...event, title: e.target.value })}
          error={errors.title}
        />
        <Input
          label={t('general.description')}
          className="resize-none"
          id="description"
          type="textarea"
          value={description}
          onChange={(e) => setEvent({ ...event, description: e.target.value })}
        />

        {type === EventViewType.REGULAR && (
          <EventDatesForm
            info={event}
            setInfo={(info) => setEvent({ ...event, ...info })}
            includeRecurrence={false}
            isDatePicker={event.isDatePicker}
            errors={errors}
          />
        )}

        <Slider
          label={t('general.location')}
          items={[
            { label: t('page.createEvent.fields.location.offline.title'), value: false },
            { label: t('page.createEvent.fields.location.online.title'), value: true },
          ]}
          value={onlineLocation}
          onChange={(value) =>
            setEvent({
              ...event,
              onlineLocation: value,
              location: '',
              locationUrl: '',
            })
          }
        />

        {onlineLocation ? (
          <input
            type="text"
            className="w-full"
            placeholder={t('page.createEvent.fields.location.online.placeholder')}
            value={locationUrl ?? ''}
            onChange={(e) => setEvent({ ...event, locationUrl: e.target.value })}
          />
        ) : (
          <AutoCompleteLocationInput
            placeholder={t('page.createEvent.fields.location.offline.placeholder')}
            location={location ?? ''}
            setLocation={(l) => setEvent({ ...event, ...l })}
          />
        )}
        <ExtraOption
          textKey="capacity"
          active={options.capacity}
          onChange={(a) => setOptions({ ...options, capacity: a })}>
          <Input
            type="number"
            min="1"
            value={maximumAttendees?.toString().replace(/^0+/, '') || '0'}
            onChange={(e) => {
              if (!Number.isNaN(+e.target.value))
                setEvent({ ...event, maximumAttendees: +e.target.value });
            }}
          />
        </ExtraOption>
      </div>
    </div>
  );
}

// PARTICIPANTS
interface ParticipantsPanelProps {
  event: Event;
  setView: (view: SECONDARY_VIEWS) => void;
}

function EventParticipantsPanel({ event, setView }: ParticipantsPanelProps) {
  const { t } = useTranslation();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);

  const getOnAnswer = (answer: EventParticipationAnswer) =>
    event.participants.filter((p) => p.answer === answer);

  return (
    <div className="flex w-full flex-1 flex-col gap-4 lg:p-4">
      <div className="flex items-center justify-between">
        <h1 className="font-serif text-[28px] leading-[28px]">
          {t('page.eventDetails.participants')}
        </h1>
        <div className="flex w-fit items-center justify-center gap-2">
          {event.participants.some((p) => p.answer !== EventParticipationAnswer.PENDING) && (
            <WithTooltip tooltip={t('tooltip.responseTimes')}>
              <Button
                className="flex-shrink-0 rounded-full p-3"
                onClick={() => setIsAnswerModalOpen(true)}
                variant="secondary">
                <FaRegClock className="size-5 flex-shrink-0" />
              </Button>
            </WithTooltip>
          )}
          <WithTooltip tooltip={t('tooltip.download')}>
            <Button
              className="flex-shrink-0 rounded-full p-3"
              onClick={() => setIsExportModalOpen(true)}
              variant="primary">
              <BiSolidDownload className="size-5 flex-shrink-0" />
            </Button>
          </WithTooltip>
          <WithTooltip tooltip={t('tooltip.editParticipants')}>
            <Button
              className="flex-shrink-0 rounded-full p-3"
              onClick={() => setView(SECONDARY_VIEWS.EDIT_PARTICIPANTS)}
              variant="secondary">
              <FaUserEdit className="size-5 flex-shrink-0" />
            </Button>
          </WithTooltip>
        </div>
      </div>
      <ParticipationAnswersOverview answers={_.countBy(event.participants, 'answer')} />
      {Object.values(EventParticipationAnswer).map(
        (type) =>
          !!getOnAnswer(type).length && (
            <div className="flex flex-col gap-4">
              <p className="font-medium text-primary">{`${getOnAnswer(type).length} ${t(
                `page.eventDetails.answerTitle.${type.toLowerCase()}`,
              )}`}</p>
              {getOnAnswer(type).map((p) => (
                <ParticipantCard key={p.id} eventId={event.id} participant={p} />
              ))}
            </div>
          ),
      )}

      <AttendanceListModal open={isExportModalOpen} setOpen={setIsExportModalOpen} event={event} />
      <AnswerUpdateModal
        open={isAnswerModalOpen}
        setOpen={setIsAnswerModalOpen}
        participants={event.participants}
      />
    </div>
  );
}

function EventEditParticipantsPanel({ event, setView }: ParticipantsPanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isDatePicker, participants } = event;
  const userId = getUserId();

  const [adding, setAdding] = useState<boolean>(false); // Whether the user is adding participants or not
  const [participantsIds, setParticipantsIds] = useState<number[]>(
    participants.filter((p) => p.id !== userId).map((p) => p.id),
  );

  const connections = useSelector((state: RootState) => state.connections).filter(
    (c) => c.userId !== userId,
  ) as Connection[];

  useBusinessEffect(() => {
    connectionService.getConnections();
  }, []);

  const handleSubmit = () => {
    const toInvite = participantsIds ?? [];

    eventService.updateParticipants(event.id, toInvite).then(() => {
      navigate(`/events/${isDatePicker ? 'date-picker/' : ''}details`, {
        state: { eventId: event.id },
      });

      setView(SECONDARY_VIEWS.PARTICIPANTS);
    });
  };

  const toggleParticipant = (idOfUser: number) =>
    setParticipantsIds(
      participantsIds.includes(idOfUser)
        ? participantsIds.filter((id) => id !== idOfUser)
        : [...participantsIds, idOfUser],
    );

  return (
    <div className="flex w-full flex-1 flex-col gap-4 lg:p-4">
      <div className="flex w-full flex-col gap-1">
        <h1 className="font-serif text-3xl">{t('page.eventDetails.editParticipants.title')}</h1>
        <p>{t('page.eventDetails.editParticipants.subtitle')}</p>
      </div>
      <ParticipantsPicker
        selected={connections.filter((c) => participantsIds.includes(c.userId!))}
        setSelected={(ids) => {
          setParticipantsIds(
            connections.filter((c) => ids.map((id) => id.id).includes(c.id!)).map((c) => c.userId!),
          );
        }}
      />
      <div className="flex gap-2">
        <Button variant="tertiary" onClick={() => setView(SECONDARY_VIEWS.PARTICIPANTS)}>
          {t('general.cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={participantsIds.length === 0}
          className="self-start">
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}

const MIN_SEARCH = 5;

interface RenderParticipantsProps {
  connections: Connection[];
  participantsIds: number[];
  toggleParticipant: (id: number) => void;
  setAdding: (adding: boolean) => void;
  handleSubmit: () => void;
  setView: (view: SECONDARY_VIEWS) => void;
  participants: EventParticipant[];
  userId: number;
  setParticipantsIds: (ids: number[]) => void;
}

function ParticipantsList({
  connections,
  participantsIds,
  toggleParticipant,
  setAdding,
  handleSubmit,
  setView,
  participants,
  userId,
  setParticipantsIds,
}: RenderParticipantsProps) {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const filteredParticipantIds = participantsIds.filter((id) => {
    const connection = connections.find((c) => c.userId === id);
    if (!connection) return false;
    const {
      fields: { FIRST_NAME, LAST_NAME },
    } = connection;
    return searchFilter(FIRST_NAME + LAST_NAME, search);
  });

  return (
    <div className="flex flex-col gap-4">
      {participants.length > MIN_SEARCH && (
        <SearchBar
          search={search}
          setSearch={setSearch}
          inputProps={{
            placeholder: t('page.createEvent.participants.searchParticipantsPlaceholder'),
          }}
        />
      )}
      <div className="grid h-full grid-cols-3 gap-4 md:grid-cols-4">
        <div className="flex aspect-square flex-col items-center gap-2 hover:opacity-80">
          <Button
            variant="primary"
            onClick={() => setAdding(true)}
            className="h-full w-full justify-center">
            <FaUserEdit className="h-full max-h-[50%] w-full max-w-[50%]" />
          </Button>
          <span
            onClick={() => setAdding(true)}
            className="cursor-pointer font-[600] text-primary-900">
            {t('page.createEvent.participants.invite')}
          </span>
        </div>

        {filteredParticipantIds.map((id) => {
          const connection = connections.find((c) => c.userId === id);
          if (!connection) return null;
          const {
            fields: { FIRST_NAME, LAST_NAME },
            picture,
          } = connection;

          return (
            <div key={id} className="relative aspect-square">
              <Button className="flex w-full cursor-default flex-col p-0">
                <Avatar
                  src={picture}
                  alias={FIRST_NAME.slice(1, 0) + LAST_NAME.slice(1, 0)}
                  className="h-full w-full rounded-[20px]"
                />
                <p className="text-wrap">{`${FIRST_NAME} ${LAST_NAME}`}</p>
              </Button>
              <button
                type="button"
                onClick={() => toggleParticipant(id)}
                className="absolute -right-2 -top-2 rounded-full bg-secondary p-1 text-secondary-50 transition-opacity hover:opacity-90">
                <FaXmark className="size-8" />
              </button>
            </div>
          );
        })}

        {filteredParticipantIds.length === 0 && participantsIds.length > 0 && (
          <div className="col-span-3 flex w-2/3 flex-col gap-2 justify-self-center text-center md:col-span-4">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noResults.title')}
            </h2>
            <span>{t('page.createEvent.participants.noResults.subtitle')}</span>
          </div>
        )}

        {participantsIds?.length === 0 && (
          <div className="col-span-3 flex w-2/3 flex-col gap-2 justify-self-center text-center md:col-span-4">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noParticipants.title')}
            </h2>
            <span>{t('page.createEvent.participants.noParticipants.subtitle')}</span>
          </div>
        )}
      </div>

      <div className="flex gap-2">
        <Button
          variant="tertiary"
          onClick={() => {
            setParticipantsIds(participants.filter((p) => p.id !== userId).map((p) => p.id)); // Reset participants
            setView(SECONDARY_VIEWS.PARTICIPANTS);
          }}
          className="col-span-3 self-start">
          {t('general.cancel')}
        </Button>
        <Button variant="primary" onClick={handleSubmit} className="col-span-3 self-start">
          {t('general.save')}
        </Button>
      </div>
    </div>
  );
}

interface RenderAddingProps {
  connections: Connection[];
  participantsIds: number[];
  toggleParticipant: (id: number) => void;
  setAdding: (adding: boolean) => void;
}

function RenderAdding({
  connections,
  participantsIds,
  toggleParticipant,
  setAdding,
}: RenderAddingProps) {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const filteredConnections = connections.filter(({ fields: { FIRST_NAME, LAST_NAME } }) =>
    searchFilter(FIRST_NAME + LAST_NAME, search),
  );

  return (
    <div className="flex flex-col gap-4">
      {connections.length > MIN_SEARCH && (
        <SearchBar
          search={search}
          setSearch={setSearch}
          inputProps={{ placeholder: t('page.createEvent.participants.searchContactsPlaceholder') }}
        />
      )}
      <div className="flex h-full flex-col gap-2">
        {filteredConnections.map(
          ({ fields: { FIRST_NAME, LAST_NAME }, id, picture, userId: Uid }) => (
            <div
              key={id}
              onClick={() => toggleParticipant(Uid!)}
              className="flex w-full cursor-pointer items-center justify-between transition-opacity hover:opacity-80 active:opacity-65">
              <div className="flex items-center gap-2">
                <Avatar
                  src={picture}
                  alias={FIRST_NAME.slice(1, 0) + LAST_NAME.slice(1, 0)}
                  className="size-24 rounded-xl"
                />
                <p>{`${FIRST_NAME} ${LAST_NAME}`}</p>
              </div>
              {participantsIds.includes(+Uid!) ? (
                <FaCheckCircle className="size-8 text-secondary" />
              ) : (
                <FaCircle className="size-8 text-secondary-200" />
              )}
            </div>
          ),
        )}

        {filteredConnections.length === 0 && connections.length > 0 && (
          <div className="flex w-full flex-col gap-2 justify-self-center text-center">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noResults.title')}
            </h2>
            <span>{t('page.createEvent.participants.noResults.subtitle')}</span>
          </div>
        )}

        {connections.length === 0 && (
          <div className="flex w-full flex-col gap-2 justify-self-center text-center">
            <h2 className="text-xl font-medium">
              {t('page.createEvent.participants.noConnections.title')}
            </h2>
            <span>{t('page.createEvent.participants.noConnections.subtitle')}</span>
          </div>
        )}
      </div>
      <Button variant="primary" onClick={() => setAdding(false)} className="mt-auto">
        {t('page.createEvent.participants.invite')}
        <FaUserEdit className="size-5" />
      </Button>
    </div>
  );
}

interface AnswerUpdateModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  participants: EventParticipant[];
}

function AnswerUpdateModal({ open, setOpen, participants }: AnswerUpdateModalProps) {
  const { t } = useTranslation();
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col justify-center gap-6 rounded-[20px] bg-secondary-50 px-6 py-8 lg:px-12">
      {eventUtils
        .sortParticipants(participants)
        .filter((p) => p.answer !== EventParticipationAnswer.PENDING)
        .map((p) => (
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="relative">
                <Avatar
                  src={p.picture}
                  alias={`${p.firstName}+${p.lastName}`}
                  className="h-10 w-10"
                />
                {p.answer && (
                  <ParticipationBadge
                    className="absolute -bottom-2 -right-2 border border-secondary-50"
                    type={p.answer}
                  />
                )}
              </div>
              <p>{`${p.firstName}  ${p.lastName}`}</p>
            </div>
            <p>
              {dateUtils.timeDifferenceString(
                p.answer ? p.updatedAt : (p.answers?.[0]?.updatedAt ?? new Date()),
                t,
              )}
            </p>
          </div>
        ))}
    </ModalLayout>
  );
}

interface ParticipationAnswersOverviewProps {
  answers: { [key in EventParticipationAnswer]?: number };
  size?: 'sm' | 'lg';
}

export function ParticipationAnswersOverview({
  answers,
  size = 'lg',
}: ParticipationAnswersOverviewProps) {
  return (
    <div className={`flex flex-wrap ${size === 'lg' ? 'gap-4' : 'gap-2'}`}>
      {Object.values(EventParticipationAnswer).map((type) => (
        <div
          className={classNames('flex flex-shrink-0 items-center text-sm', {
            'opacity-25': !answers[type],
            'gap-2': size === 'lg',
            'gap-1': size === 'sm',
          })}>
          <ParticipationBadge type={type} size={size} />
          <p className="min-w-2 flex-shrink-0 text-sm">{answers[type] ?? 0}</p>
        </div>
      ))}
    </div>
  );
}

type ParticipantCardProps = {
  participant: EventParticipant;
} & (
  | { includeButton?: true; eventId: number; badge?: never }
  | { includeButton: false; eventId?: never; badge?: 'avatar' | 'right' }
);

function ParticipantCard({
  participant: { picture, firstName, lastName, answer, description, id },
  includeButton = true,
  badge = 'avatar',
  eventId,
}: ParticipantCardProps) {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="relative">
          <Avatar className="h-12 w-12" src={picture} alias={`${firstName}+${lastName}`} />
          {answer && badge === 'avatar' && (
            <ParticipationBadge
              type={answer}
              className="absolute -bottom-2 -right-2 border border-secondary-50"
            />
          )}
        </div>
        <div className="flex flex-col">
          <p>{`${firstName} ${lastName}`}</p>
          {description && <p className="text-xs italic">“{description}”</p>}
        </div>
      </div>
      {includeButton && (
        <ParticipantButton eventId={eventId!} participantId={id} answer={answer!} />
      )}
      {answer && badge === 'right' && <ParticipationBadge type={answer} />}
    </div>
  );
}

interface ParticipantButtonProps {
  eventId: number;
  participantId: number;
  answer: EventParticipationAnswer;
}

function ParticipantButton({ eventId, participantId, answer }: ParticipantButtonProps) {
  const [warningOptions, setWarningOptions] = useState<SimpleWarningModalProps | null>(null);
  const { t } = useTranslation();

  const [view, setView] = useState<'OPTIONS' | 'PRESENCE'>('OPTIONS');
  const [presence, setPresence] = useState<EventParticipationAnswer | undefined>(answer);

  const handleSelectPresence = () => {
    eventService.setAttendance(eventId, participantId, presence!);
  };

  return (
    <>
      <ActionMenu>
        <ActionMenuButton
          icon={TbBell}
          text={t('page.eventDetails.remind')}
          onClick={() =>
            setWarningOptions({
              title: t('warning.remindUserEvent.title'),
              description: t('warning.remindUserEvent.description'),
              button: t('general.send'),
              open: true,
              onClose: () =>
                eventService.remindUserForEvent(eventId, participantId).then(() => {
                  toast.success(t('toast.success.remindedUser'));
                }),
            })
          }
        />
        {view === 'OPTIONS' && (
          <ActionMenuButton
            icon={TbCheck}
            text={t('page.eventDetails.changePresence')}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setView('PRESENCE');
            }}
          />
        )}
        {view === 'PRESENCE' && (
          <div className="flex flex-col gap-2 py-1">
            <div className="flex w-full justify-center gap-4">
              {Object.values(
                _.omit(EventParticipationAnswer, EventParticipationAnswer.PENDING),
              ).map((a) => (
                <Button
                  onClick={() => setPresence(presence === a ? undefined : a)}
                  className={`rounded-lg border-2 border-primary-300 p-1 hover:border-opacity-80 ${
                    presence === a
                      ? 'bg-primary-300 text-primary-900 hover:bg-opacity-80'
                      : 'text-primary-300 hover:text-opacity-80'
                  }`}>
                  <Icon icon={eventParticipantAnswerIcons[a]} className="h-4 w-4" />
                </Button>
              ))}
            </div>
            <div className="flex items-center justify-center">
              <Button onClick={() => setView('OPTIONS')} className="px-2 py-1 text-xs">
                {t('general.cancel')}
              </Button>
              <Button
                onClick={handleSelectPresence}
                variant="secondary"
                disabled={!presence || presence === answer}
                className="px-3 py-1 text-xs">
                {t('page.eventDetails.editPresence')}
              </Button>
            </div>
          </div>
        )}
        <ActionMenuButton
          className="text-danger"
          icon={TbUserMinus}
          text={t('page.eventDetails.deleteUser')}
          onClick={() =>
            setWarningOptions({
              title: t('warning.removeUserEvent.title'),
              description: t('warning.removeUserEvent.description'),
              button: t('general.delete'),
              open: true,
              saveClassName: 'bg-danger text-secondary-50',
              onClose: () => eventService.deleteParticipant(eventId, participantId),
            })
          }
        />
      </ActionMenu>
      <WarningModal
        {...(warningOptions ?? { title: '', description: '', open: false })}
        setOpen={() => setWarningOptions({ ...warningOptions!, open: false })}
      />
    </>
  );
}

//  POLLS
interface PollsPanelProps {
  polls: EventCustomField[];
}

function PollsPanel({ polls }: PollsPanelProps) {
  const { t } = useTranslation();
  if (!polls.length) return null;
  return (
    <div className="flex flex-col gap-2">
      <h1 className="font-serif text-[28px] leading-[28px]">{t('page.eventDetails.polls')}</h1>
      <div className="flex flex-col gap-8">
        {polls.map((poll) => (
          <PollResult key={poll.customFieldId} poll={poll} />
        ))}
      </div>
    </div>
  );
}

interface PollResultProps {
  poll: EventCustomField;
}

function PollResult({ poll }: PollResultProps) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const getAlias = (response: EventCustomFieldResponse) =>
    `${response.user.firstName}+${response.user.lastName}`;

  const getResponses = (option: string) => poll.responses.filter((r) => r.response === option);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <p className="font-medium text-primary">{poll.label}</p>
        <p className="font-medium text-primary">
          {poll.responses.length}{' '}
          {t('page.eventDetails.reactions', { count: poll.responses.length })}
        </p>
      </div>
      {poll.type === CustomFieldType.MULTIPLE_CHOICE &&
        poll.options.map((option) => (
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <p className="text-sm"> {option} </p>
              <p className="text-sm"> {getResponses(option).length} </p>
            </div>
            <ProgressBar
              progress={(getResponses(option).length / poll.responses.length) * 100}
              variant="secondary"
            />
          </div>
        ))}
      {!!poll.responses.length && (
        <Button className="p-0 underline" onClick={() => setModalOpen(true)}>
          {t('page.eventDetails.viewAnswers')}
        </Button>
      )}

      <ModalLayout
        closeButton
        open={modalOpen}
        setOpen={setModalOpen}
        className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col gap-6 rounded-[20px] bg-secondary-50 px-12 py-8 pb-4">
        {poll.type === CustomFieldType.MULTIPLE_CHOICE &&
          poll.options.map((option) => (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="font-medium text-primary"> {option} </p>
                <p className="font-medium text-primary">
                  {getResponses(option).length}{' '}
                  {t('page.eventDetails.reactions', { count: getResponses(option).length })}
                </p>
              </div>
              {getResponses(option).map((response) => (
                <div className="flex items-center gap-2">
                  <Avatar
                    className="h-8 w-8"
                    src={response.user.picture}
                    alias={getAlias(response)}
                  />
                  <p className="text-sm">{getAlias(response).replace('+', ' ')}</p>
                </div>
              ))}
            </div>
          ))}
        {poll.type === CustomFieldType.TEXT &&
          poll.responses.map((response) => (
            <div className="flex items-center gap-2">
              <Avatar
                className="h-10 w-10 text-lg"
                src={response.user.picture}
                alias={getAlias(response)}
              />
              <div className="flex flex-col">
                <p className="text-sm">{getAlias(response).replace('+', ' ')}</p>
                <p className="italic">&quot;{response.response}&quot;</p>
              </div>
            </div>
          ))}
      </ModalLayout>
    </div>
  );
}

interface DateOptionsPanelProps {
  options: DateOption[];
}
function DateOptionsPanel({ options }: DateOptionsPanelProps) {
  const { t } = useTranslation();

  const maxScore = options.reduce((acc, o) => Math.max(acc, eventUtils.scoreDateOption(o)), 0);
  const [bestOptions, otherOptions] = _.partition(
    options,
    (o) => eventUtils.scoreDateOption(o) === maxScore,
  ).map((os) => eventUtils.sortDateOptions(os));
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className="flex flex-col gap-2">
      <h1 className="font-serif text-[28px] leading-[28px]">
        {t('page.eventDetails.dateOptions')}
      </h1>
      <div className="flex flex-col gap-2">
        <h1 className="font-serif text-[18px] leading-[24px]">
          {t('page.eventDetails.bestDateOptions')}
        </h1>
        <div className="flex flex-col gap-6">
          {bestOptions.map((option) => (
            <DateOptionResult key={option.id} option={option} />
          ))}
        </div>
      </div>
      {!!otherOptions.length && (
        <div className="mt-4 flex flex-col gap-2">
          <h1 className="font-serif text-[18px] leading-[24px]">
            {t('page.eventDetails.otherDateOptions')}
          </h1>
          <Button variant="tertiary" onClick={() => setCollapsed(!collapsed)}>
            {t(`page.eventDetails.${collapsed ? 'show' : 'hide'}Other`)}
            <FaChevronDown
              className={classNames('size-5 transition-all', { 'rotate-180': !collapsed })}
            />
          </Button>
          <div
            className={classNames('flex flex-col gap-6 overflow-hidden transition-all', {
              'max-h-[0px] duration-500 ease-[cubic-bezier(0,1,0,1)]': collapsed,
              'max-h-[2000px] duration-1000 ease-[ease-in-out]': !collapsed,
            })}>
            {otherOptions.map((option) => (
              <DateOptionResult key={option.id} option={option} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

interface DateOptionResultProps {
  option: DateOption;
}

function DateOptionResult({ option }: DateOptionResultProps) {
  const { answers } = option;

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  const resolvedAnswers = answers.filter(
    ({ answer }) => answer !== EventParticipationAnswer.PENDING,
  );
  const percentage = eventUtils.calculatePercentage(option);

  return (
    <div className="flex w-full flex-col gap-3">
      <p className="font-medium text-primary">{eventUtils.formatDate(option)}</p>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <IconBadge icon={FaRegClock} className="rounded-[6px]" />
          <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
        </div>
        <ParticipationAnswersOverview answers={_.countBy(answers, 'answer')} />
      </div>
      <ProgressBar progress={percentage} />
      {!!resolvedAnswers.length && (
        <Button className="p-0 underline" onClick={() => setModalOpen(true)}>
          {t('page.eventDetails.viewAnswers')}
        </Button>
      )}
      <ModalLayout
        open={modalOpen}
        setOpen={setModalOpen}
        closeButton
        className="z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col gap-6 rounded-[20px] bg-secondary-50 px-12 py-8">
        <div className="flex flex-col gap-2">
          <h1 className="font-serif text-[28px] leading-[28px]">{eventUtils.formatDate(option)}</h1>
          <div className="flex items-center gap-2">
            <IconBadge icon={FaRegClock} className="rounded-[6px]" />
            <p className="text-sm">{eventUtils.formatDate(option, 'time')}</p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="font-serif text-[18px] leading-[24px]">
            {t('page.eventDetails.participants')}
          </h2>
          {resolvedAnswers.map((a) => (
            <ParticipantCard
              key={a.id}
              participant={a as EventParticipant}
              includeButton={false}
              badge="right"
            />
          ))}
        </div>
      </ModalLayout>
    </div>
  );
}

interface DatePickerResponsesOverviewPanelProps {
  event: Event;
  setView: (view: SECONDARY_VIEWS) => void;
}

function DatePickerResponsesOverviewPanel({
  event: { participants },
  setView,
}: DatePickerResponsesOverviewPanelProps) {
  const { t } = useTranslation();

  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);

  const [responded, notResponded] = _.partition(participants, (p) =>
    p.answers?.some(({ answer }) => answer !== EventParticipationAnswer.PENDING),
  );

  return (
    <div className="flex w-full flex-1 flex-col gap-8 lg:p-4">
      {!!responded.length && (
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <h1 className="mt-auto font-serif text-[28px] leading-[28px]">
              {t('page.eventDetails.responded')}
            </h1>
            <div className="flex w-fit items-center justify-center gap-2">
              {responded.length > 0 && (
                <Button
                  className="flex-shrink-0 rounded-full p-3"
                  onClick={() => setIsAnswerModalOpen(true)}
                  variant="secondary">
                  <FaRegClock className="size-5 flex-shrink-0" />
                </Button>
              )}
              <Button
                className="flex-shrink-0 rounded-full p-3"
                onClick={() => setView(SECONDARY_VIEWS.EDIT_PARTICIPANTS)}
                variant="secondary">
                <FaUserEdit className="size-5 flex-shrink-0" />
              </Button>
            </div>
          </div>
          {responded.map((p) => (
            <ParticipantCard key={p.id} participant={p} includeButton={false} />
          ))}
        </div>
      )}

      {!!notResponded.length && (
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <h1 className="font-serif text-[28px] leading-[28px]">
              {t('page.eventDetails.notResponded')}
            </h1>
            {!responded.length && (
              <Button
                className="flex-shrink-0 rounded-full p-3"
                onClick={() => setView(SECONDARY_VIEWS.EDIT_PARTICIPANTS)}
                variant="secondary">
                <FaUserEdit className="size-5 flex-shrink-0" />
              </Button>
            )}
          </div>

          {notResponded.map((p) => (
            <ParticipantCard key={p.id} participant={p} includeButton={false} />
          ))}
        </div>
      )}

      <AnswerUpdateModal
        open={isAnswerModalOpen}
        setOpen={setIsAnswerModalOpen}
        participants={responded}
      />
    </div>
  );
}
