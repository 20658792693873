import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaUserPlus } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { Connection } from '../../../redux/slices/connectionsSlice';
import { searchFilter } from '../../../utils/filterUtils';
import Button from '../buttons/Button';
import SearchBar from '../forms/SearchBar';
import Avatar from './Avatar';
import ChangeSelectionModal from '../modals/ChangeSelectionModal';
import { ConnectionIdentifier } from '../../../types/misc';
import { RootState } from '../../../redux/reducers';

const MIN_SEARCH = 5;

type ParticipantsPickerProps = {
  disable?: (connection: Connection) => boolean;
  disabledMessage?: string;
  selected: ConnectionIdentifier[];
  setSelected(connections: ConnectionIdentifier[]): void;
};

export default function ParticipantsPicker({
  disable = () => false,
  disabledMessage,
  selected,
  setSelected,
}: ParticipantsPickerProps) {
  const { t } = useTranslation();
  const [adding, setAdding] = useState(false);
  const connections = useSelector((state: RootState) => state.connections);

  const [search, setSearch] = useState<string>('');
  const filteredParticipants = connections
    .filter(({ fields }) => searchFilter(fields.FIRST_NAME + fields.LAST_NAME, search))
    .filter((c) => selected.some((s) => s.id === c.id && s.type === c.type));

  return (
    <>
      <div className="flex flex-col gap-8">
        {connections.length > MIN_SEARCH && (
          <SearchBar
            search={search}
            setSearch={setSearch}
            inputProps={{
              placeholder: t('page.createEvent.participants.searchParticipantsPlaceholder'),
            }}
          />
        )}
        <div className="h-full flex flex-wrap gap-4">
          <div className="flex h-fit w-fit flex-col items-center gap-2 hover:opacity-80">
            <Button
              key="inviteButton"
              variant="primary"
              onClick={() => setAdding(true)}
              className="h-[80px] w-[80px] items-center justify-center rounded-full">
              <FaUserPlus className="h-14 w-14" />
            </Button>
            <span
              onClick={() => setAdding(true)}
              className="cursor-pointer font-[600] text-primary-900">
              {t('page.createEvent.participants.invite')}
            </span>
          </div>

          {filteredParticipants.map(({ id, fields, picture, type }) => (
            <div key={id} className="relative h-fit w-fit">
              <div className="flex h-fit w-fit flex-col gap-2">
                <Avatar
                  src={picture}
                  alias={`${fields.FIRST_NAME}+${fields.LAST_NAME}`}
                  className="h-[80px] w-[80px] rounded-full bg-secondary-200 text-3xl font-semibold text-secondary-50"
                />
                <p className="max-w-[80px] text-center font-semibold">{fields.FIRST_NAME}</p>
              </div>
              <button
                type="button"
                onClick={() => setSelected(selected.filter((s) => !(s.id === id && s.type === type)))}
                className="absolute -right-1 -top-1 rounded-full bg-secondary p-1 text-secondary-50 shadow-lg transition-opacity hover:opacity-90">
                <FaXmark className="size-6" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <ChangeSelectionModal
        open={adding}
        setOpen={setAdding}
        selection={selected}
        setSelection={setSelected}
        type="custom"
        customSelection={connections.filter((c) => !disable(c))}
        disabledMessage={disabledMessage}
      />
    </>
  );
}
