import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { TbCaretDownFilled } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

type CollapsableProps = {
  label: string;
  children: React.ReactNode;
  collapsed?: boolean;
  labelClassName?: string;
  childrenContainerClassName?: string;
}

export default function Collapsable({ children, collapsed, label, labelClassName, childrenContainerClassName }: CollapsableProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(collapsed === undefined ? true : !collapsed);

  return (
    <div>
      <button className="flex items-center gap-2" type="button" onClick={() => setIsOpen(prev => !prev)}>
        <TbCaretDownFilled className={`transition-transform text-2xl ${isOpen ? 'rotate-0' : '-rotate-90'}`} />
        <p className={twMerge('bg-primary-300 text-secondary-50 rounded-lg px-2 py-1.5 uppercase', labelClassName)}>{label}</p>
      </button>
      <Transition
        show={isOpen}
        enter="transition-all duration-200"
        enterFrom="opacity-0 transform scale-95 translate-y-1"
        enterTo="opacity-100 transform scale-100 -translate-y-0"
        leave="transition-all duration-200"
        leaveFrom="opacity-100 transform scale-100 -translate-y-0"
        leaveTo="opacity-0 transform scale-95 translate-y-1"
        className={twMerge('py-6', childrenContainerClassName)}
      >
        {children}
      </Transition>
    </div>
  );
}
