import React, { useRef } from 'react';

type ProgressBarProps = {
  progress: number;
  variant?: 'primary' | 'primary-light' | 'secondary';
  showText?: boolean;
};

const variantMap: Record<'primary' | 'primary-light' | 'secondary', { bg: string, text: string }> = {
  primary: { bg: 'bg-primary-900', text: 'text-secondary-50' },
  'primary-light': { bg: 'bg-primary-300', text: 'text-secondary-50' },
  secondary: { bg: 'bg-secondary', text: 'text-primary-900' },
};

export default function ProgressBar({ progress, variant = 'primary', showText = true }: ProgressBarProps) {
  progress = Math.round(progress || 0);

  const textRef = useRef<HTMLParagraphElement>() as React.MutableRefObject<HTMLInputElement>;

  const filledWidth = `${Math.min(Math.max(progress, 0), 100)}%`;
  const textPosition = (100 * progress) / 100 / 2;
  const textWidthOffset = ((textRef.current?.offsetWidth ?? 0) / 2) * -1;

  const backgroundColor = variantMap[variant].bg;
  const textColor = variantMap[variant].text;

  return (
    <div
      className={`relative flex h-6 w-full overflow-hidden rounded-full ${
        progress === 0 && 'flex-row justify-center'
      } ${variant === 'primary-light' ? 'bg-secondary-200' : `${backgroundColor} bg-opacity-20`}`}>
      {showText && progress === 0 && <p className="leading-2 mx-auto my-auto text-xs">{progress}%</p>}
      <div className={`h-full ${backgroundColor} transition-all delay-500 duration-500`} style={{ width: filledWidth }} />
      {showText && progress >= 10 && (
        <p
          ref={textRef}
          style={{
            left: `${textPosition}%`,
            transform: `translate(${textWidthOffset}px, 0px)`,
          }}
          className={`leading-2 absolute top-0 flex h-full items-center justify-center text-xs ${textColor}`}>
          {progress}%
        </p>
      )}
    </div>
  );
}
