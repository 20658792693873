import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbArrowRight, TbCreditCard, TbHeart, TbHeartBroken } from 'react-icons/tb';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { BiInfoCircle } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { SiMastercard, SiPaypal, SiSepa } from 'react-icons/si';
import { FaCcAmex, FaCcVisa } from 'react-icons/fa6';
import Button from '../../components/buttons/Button';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import CheckoutForm from '../../components/forms/CheckoutForm';
import ModalLayout from '../../components/layouts/ModalLayout';
import {
  getUsageLimit,
  memberCountPrices,
  subscriptionColors,
} from '../../../utils/subscriptionUtils';
import { InvoiceData, PaymentDetails } from '../../../types/payments';
import { COLOR_SECONDARY_50, stripeAppearance } from '../../../constants';
import { SubscriptionType } from '../../../types/misc';
import InvoicesTable from '../../components/tables/InvoicesTable';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { formatDate } from '../../../utils/stringUtils';
import businessService from '../../../services/businessService';
import { subscriptionService } from '../../../services/subscriptionService';
import {
  BaseTable,
  BaseTableBody, BaseTableDataRow,
  BaseTableHeader,
  BaseTableHeaderRow,
  BaseTableHelperText,
} from '../../components/tables/BaseTable';
import FeedbackCards from '../../components/cards/FeedbackCards';

const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY as string;
const stripe = loadStripe(STRIPE_KEY);

export default function ManageSubscriptionPage(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const { t: subscriptionT } = useTranslation('', { keyPrefix: 'page.settings.subscriptions' });
  const activeSubscription = useActiveSubscription();
  const [clientSecret, setClientSecret] = useState<string | undefined>(undefined);
  const [editPaymentModalOpen, setEditPaymentModalOpen] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>();
  const [lastInvoices, setLastInvoices] = useState<InvoiceData[]>();
  const [upcomingInvoice, setUpcomingInvoice] = useState<InvoiceData>();
  const [userCount, setUserCount] = useState(0);
  const [limitUsers, setLimitUsers] = useState(0);
  const [endDate, setEndDate] = useState<Date | null>();
  const selectedBusiness = useSelectedBusiness();
  const navigate = useNavigate();

  useEffect(() => {
    businessService.fetchBusinesses();
  }, []);

  useEffect(() => {
    subscriptionService
      .getPaymentDetails()
      .then(setPaymentDetails)
      .catch(() => {
        setPaymentDetails(null);
      });
    subscriptionService
      .getLastInvoices(3)
      .then(setLastInvoices)
      .catch(() => setLastInvoices([]));
    subscriptionService
      .getUpcomingInvoice()
      .then(setUpcomingInvoice)
      .catch(() => setUpcomingInvoice(undefined));
    subscriptionService.getSubscriptionUsage().then((count) => {
      setUserCount(count);
      setLimitUsers(getUsageLimit(activeSubscription, count).max);
    });
    subscriptionService
      .getEndDate()
      .then((x: number) => setEndDate(new Date(x * 1000)))
      .catch(() => setEndDate(null));
  }, [activeSubscription]);

  useEffect(() => {
    if (editPaymentModalOpen && clientSecret === undefined) {
      subscriptionService.createSetupIntent().then((x) => setClientSecret(x.clientSecret));
    }
  }, [editPaymentModalOpen]);

  const options = {
    appearance: { ...stripeAppearance },
    clientSecret,
  } as StripeElementsOptions;

  return (
    <>
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-4">
            <h2 className="font-serif text-2xl">{t('title')}</h2>
            <span className="rounded-xl border-2 border-primary-900 px-4 py-0.5 text-lg font-semibold uppercase">
              {subscriptionT(`${activeSubscription.toLowerCase()}.title`)}
            </span>
          </div>
          <p className="w-1/3 text-gray-500">{t('subtitle')}</p>
        </div>
      </div>
      <div className="my-4 flex items-center justify-between rounded-xl bg-secondary-200 bg-opacity-50 px-8 py-3">
        <div className="flex flex-col">
          <p className="font-semibold">{t('yourSubscription')}</p>
          <h3 className="mt-2 flex items-center gap-2 text-2xl font-semibold">
            {subscriptionT(`${activeSubscription.toLowerCase()}.title`)}
            {selectedBusiness?.subscription?.willRenew === false && (
              <>
                <TbArrowRight />
                {subscriptionT(`free.title`)}
              </>
            )}
          </h3>
          <p className="text-xs text-gray-500">{t('subtitle')}</p>
        </div>
        <div className="flex flex-col items-end justify-evenly gap-2">
          <div className="flex items-center gap-1">
            {userCount}/{limitUsers === Infinity ? '∞' : limitUsers} {t('membersAvailable')}
            <div className="group relative">
              <BiInfoCircle />
              <ExplanationPopup limitUsers={limitUsers} />
            </div>
          </div>
          <Button variant="primary" onClick={() => navigate('/settings/subscriptions')}>
            {t('changeSubscription')}
          </Button>
        </div>
      </div>
      <InvoicesTable invoices={lastInvoices || []} loading={lastInvoices === undefined} />
      <Button
        onClick={() => navigate('/subscriptions/invoices')}
        variant="primary"
        className="mt-2 self-end">
        {t('showAllInvoices')}
      </Button>
      <div className="ml-8 mt-4">
        <BaseTable>
          <BaseTableHeader>
            <BaseTableHeaderRow>
              <th>{t('nextPaymentDue')}</th>
              <th>{t('total')}</th>
              <th>{t('status')}</th>
            </BaseTableHeaderRow>
          </BaseTableHeader>
          <BaseTableBody>
            <BaseTableHelperText>{t('nextPaymentHelper')}</BaseTableHelperText>
            {upcomingInvoice && (
              <BaseTableDataRow>
                <td>{formatDate(new Date(upcomingInvoice.date))}</td>
                <td>&euro;{upcomingInvoice.amount.toFixed(2)}</td>
                <td>
                  <div className="mb-1 flex w-fit items-center rounded-lg bg-primary-300 px-2 py-1.5 capitalize text-secondary-50">
                    {upcomingInvoice.status}
                  </div>
                </td>
              </BaseTableDataRow>
            )}
            {selectedBusiness?.subscription?.willRenew === false && endDate && (
              <BaseTableHelperText className="col-span-3 ml-8 rounded-md border border-secondary px-2 py-1 font-bold text-secondary text-base">
                {t('cancelledText', { date: formatDate(endDate) })}
              </BaseTableHelperText>
            )}
          </BaseTableBody>
        </BaseTable>
      </div>
      <div className="ml-8 mt-4">
        <div className="bg-secondary-200 bg-opacity-50 py-1 pl-8 rounded-full font-semibold">
          <p>{t('paymentMethod')}</p>
        </div>
        <p className="ml-8 py-2 text-sm text-gray-500">{t('paymentMethodHelper')}</p>
        {paymentDetails === undefined ? (
          <div className="h-12 w-full animate-pulse rounded-xl bg-secondary-200" />
        ) : (
          <PaymentDetailsRow
            paymentDetails={paymentDetails}
            openPaymentModal={() => setEditPaymentModalOpen(true)}
          />
        )}
      </div>
      <FeedbackCards className="mt-4 px-8 py-2" />
      <div className="mr-8 mt-2 flex justify-end gap-2">
        {selectedBusiness?.subscription?.willRenew === true && (
          <Button
            onClick={() => {
              subscriptionService.cancelSubscription().then(() => {
                businessService.fetchBusinesses();
              });
            }}
            variant="danger">
            <TbHeartBroken />
            {t('cancel')}
          </Button>
        )}
        {selectedBusiness?.subscription?.willRenew === false && (
          <Button
            onClick={() => {
              subscriptionService.reactivateSubscription().then(() => {
                businessService.fetchBusinesses();
              });
            }}
            variant="danger">
            <TbHeart />
            {t('reactivate')}
          </Button>
        )}
      </div>
      <ModalLayout
        open={editPaymentModalOpen}
        setOpen={setEditPaymentModalOpen}
        closeButton
        closeButtonClassName={subscriptionColors[activeSubscription].text}>
        <div
          className={`${subscriptionColors[activeSubscription].bg} w-[40rem] rounded-xl px-10 py-6`}>
          <h3 className={`${subscriptionColors[activeSubscription].text} pt-4 text-xl font-bold`}>
            {t('newPaymentMethodTitle')}
          </h3>
          {clientSecret && stripe ? (
            <Elements options={options} stripe={stripe}>
              <CheckoutForm
                clientSecret={clientSecret}
                disabled={false}
                setDisabled={() => false}
                intentType="setupChange"
                onSubmit={() => {}}
                redirectQueryParams=""
                containerClassName="bg-secondary-50"
                buttonClassName="bg-secondary-50 text-primary-900"
              />
            </Elements>
          ) : (
            <></>
          )}
        </div>
      </ModalLayout>
    </>
  );
}

type PaymentDetailsRowProps = {
  paymentDetails: PaymentDetails | null;
  openPaymentModal: () => void;
};

function PaymentDetailsRow({ paymentDetails, openPaymentModal }: PaymentDetailsRowProps) {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const selectedBusiness = useSelectedBusiness();

  return (
    <div className="pl-8 flex items-center justify-between border-b border-b-gray-300">
      <div className="flex items-center gap-2 py-2">
        {paymentDetails ? (
          paymentDetails.type === 'card' ? (
            <>
              <div className="pr-2">
                {paymentDetails.brand === 'visa' ? (
                  <FaCcVisa size={36} />
                ) : paymentDetails.brand === 'mastercard' || paymentDetails.brand === 'maestro' ? (
                  <SiMastercard size={30} />
                ) : paymentDetails.brand === 'amex' ? (
                  <FaCcAmex size={30} />
                ) : (
                  <TbCreditCard size={30} />
                )}
              </div>
              <div>
                <p>
                  {t('card')} {t('endingIn')} {paymentDetails.last4}
                </p>
                <p className="text-gray-500">
                  {t('expiryDate')}: {paymentDetails.exp_month}/{paymentDetails.exp_year}
                </p>
              </div>
            </>
          ) : paymentDetails.type === 'sepa_debit' ? (
            <>
              <div>
                <SiSepa size={30} />
              </div>
              <p>
                {paymentDetails.name} - {paymentDetails.bank_code} {t('endingIn')}{' '}
                {paymentDetails.last4}
              </p>
            </>
          ) : (
            <>
              <div>
                <SiPaypal size={30} />
              </div>
              <p>
                {paymentDetails.name && `${paymentDetails.name}, `}
                {paymentDetails.email}
              </p>
            </>
          )
        ) : (
          <p>{t('noPaymentMethod')}</p>
        )}
      </div>
      <Button
        disabled={!!selectedBusiness?.trialEndDate || !selectedBusiness?.subscription}
        onClick={openPaymentModal}
        className="mb-1"
        variant="primary">
        {t('editPaymentMethod')}
      </Button>
    </div>
  );
}

type ExplanationPopupProps = { limitUsers: number };

function ExplanationPopup({ limitUsers }: ExplanationPopupProps) {
  const { t } = useTranslation('', { keyPrefix: 'page.settings.manageSubscription' });
  const activeSubscription = useActiveSubscription();

  return (
    <div className="absolute right-0 top-0 z-10 hidden min-w-96 rounded-md bg-primary-900 p-2 text-secondary-50 group-hover:block">
      <BiInfoCircle className="absolute right-0 top-0" color={COLOR_SECONDARY_50} />
      <h4 className="mb-2">{t('priceDescription.title')}</h4>
      {activeSubscription === SubscriptionType.FREE ? (
        <div className="flex items-center gap-1">
          <div className="flex h-5 w-5 items-center justify-center rounded-full bg-secondary-200">
            <div className="h-3 w-3 rounded-full bg-primary-300" />
          </div>
          <p className="text-secondary-50">{t('priceDescription.free')}</p>
        </div>
      ) : (
        <ul>
          {memberCountPrices[activeSubscription].map(({ min, max, fee }) => (
            <li className="flex items-center gap-1" key={`sub_type_${min}_${max}_${fee}`}>
              <div className="flex h-5 w-5 items-center justify-center rounded-full bg-secondary-200">
                {limitUsers === max && <div className="h-3 w-3 rounded-full bg-primary-300" />}
              </div>
              {max === Infinity ? (
                <p className="text-secondary-50">
                  {t('priceDescription.moreThan', { x: min - 1 })}:{' '}
                  <Link className="underline" to="/contact">
                    {t('priceDescription.contactUs')}
                  </Link>
                </p>
              ) : (
                `${t('priceDescription.fromTo', { from: min, to: max })}: €${fee}`
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
