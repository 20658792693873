import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HiUserGroup } from 'react-icons/hi';
import { RootState } from '../../../redux/reducers';
import ModalLayout from '../layouts/ModalLayout';
import useBusinessTranslation from '../../../hooks/useBusinessTranslation';
import { ConnectionIdentifier } from '../../../types/misc';
import CancelSaveButton from '../misc/CancelSaveButton';
import ConnectionsList from '../misc/ConnectionsList';

interface ChangeSelectionModalProps {
  selection: ConnectionIdentifier[];
  setSelection: (connections: ConnectionIdentifier[]) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  type: 'phone' | 'email' | 'address' | 'none' | 'custom';
  customSelection?: ConnectionIdentifier[];
  disabledMessage?: string;
}
export default function ChangeSelectionModal({
  selection,
  setSelection,
  open,
  setOpen,
  type: modalType,
  customSelection,
  disabledMessage,
}: ChangeSelectionModalProps): JSX.Element {
  const { t } = useBusinessTranslation();
  const connections = useSelector((state: RootState) => state.connections);
  const [selected, setSelected] = useState<ConnectionIdentifier[]>([]);

  useEffect(() => {
    if (!open) return;
    setSelected(selection);
  }, [open]);

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="h-a z-50 mx-6 flex min-w-[450px] max-w-[600px] flex-col rounded-[20px] bg-secondary-50 px-10 py-6 pb-4 pt-5">
      <div className="flex gap-2 items-center">
        <HiUserGroup className="h-6 w-6" />
        <h1 className="font-serif text-2xl">{t('component.modal.selectionChange.title')}</h1>
      </div>
      <ConnectionsList
        className="border-none"
        disabledMessage={disabledMessage}
        connections={connections.map(
          ({ id, type, fields: { FIRST_NAME, LAST_NAME, ADDRESS, EMAIL, PHONENUMBER } }) => {
            // Create a flag to track if the connection should be disabled
            let isDisabled = false;
            // Disable based on modal type (phone, email, address) if required fields are missing
            if (modalType === 'email' && !EMAIL) isDisabled = true;
            if (modalType === 'phone' && !PHONENUMBER) isDisabled = true;
            if (modalType === 'address' && !ADDRESS) isDisabled = true;

            // Disable based on "custom" type, if the connection is not in customSelection
            if (modalType === 'custom' && customSelection) {
              isDisabled = !customSelection.some(
                (customConnection) =>
                  +customConnection.id === +id && customConnection.type === type,
              );
            }

            return {
              id: { id, type },
              alias: `${FIRST_NAME} ${LAST_NAME}`,
              disabled: isDisabled, // Set the disabled flag
            };
          },
        )}
        selected={selected}
        setSelected={setSelected}
        equalityFunction={(a, b) => a.id === b.id}
      />
      <div className="mt-6 flex w-full justify-end">
        <CancelSaveButton
          onCancel={() => setOpen(false)}
          disabled={!selected.length}
          onSave={() => {
            setSelection(selected);
            setOpen(false);
          }}
        />
      </div>
    </ModalLayout>
  );
}

