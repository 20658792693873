import React from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { PropsOf } from '@headlessui/react/dist/types';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';
import { Pagination } from '../../../hooks/effects/usePagination';

/**
 * BaseTable is a table component that is used to create a table with a consistent style.
 * @example
 * <BaseTable>
 *   <BaseTableHeader>
 *     <BaseTableHeaderRow>
 *       <th>...</th>
 *     </BaseTableHeaderRow>
 *   </BaseTableHeader>
 *   <BaseTableHelper>...</BaseTableHelper>
 *   <BaseTableBody>
 *     <BaseTableDataRow>
 *       <td>...</td>
 *     </BaseTableDataRow>
 *   </BaseTableBody>
 * </BaseTable>
 */
export function BaseTable({ className, ...props }: PropsOf<'table'>): JSX.Element {
  return <table className={twMerge('z-0 w-full table-auto', className)} {...props} />;
}

export function BaseTableHeader({ className, children }: {
  className?: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <thead
      className={twMerge(
        '',
        className,
      )}>
    {children}
    </thead>
  );
}

export function BaseTableHeaderRow({ className, ...props }: PropsOf<'tr'>): JSX.Element {
  return <tr
    className={twMerge('text-left [&_th]:bg-secondary-200 [&_th]:bg-opacity-50 [&_th]:py-1 [&_th:first-child]:pl-8 [&_th:first-child]:rounded-l-full [&_th:last-child]:rounded-r-full', className)} {...props} />;
}

export function BaseTableBody({ className, ...props }: PropsOf<'tbody'>): JSX.Element {
  return <tbody className={twMerge('', className)} {...props} />;
}

/**
 * Creates a spacer row of 1 rem height
 * @param className Use this to override how tall the spacing is with a h-[...] class
 * @param props The rest of the props to pass to the tr element
 */
export function BaseTableSpacerRow({ className, ...props }: Omit<PropsOf<'tr'>, 'children'>): JSX.Element {
  return <tr className={twMerge('h-4', className)} {...props} />;
}

export function BaseTableDataRow({ className, ...props }: PropsOf<'tr'>): JSX.Element {
  return <tr className={twMerge(
    'relative [&_td:first-child]:pl-8 [&_td]:border-b-gray-300 [&_td]:border-b',
    className)} {...props} />;
}

/**
 * Component to create a full-width row with a helper text
 *
 * @example
 * <BaseTableBody>
 *   <BaseTableHelperText>...</BaseTableHelperText>
 * </BaseTableBody>
 */
export function BaseTableHelperText({ className, ...props }: PropsOf<'p'>): JSX.Element {
  return (
    <tr>
      <td colSpan={42}><p className={twMerge('pl-8 py-2 text-sm text-gray-500', className)} {...props} /></td>
    </tr>
  );
}

/**
 * Component to create a full-width row with a helper text
 *
 * @example
 * <BaseTableBody>
 *   <BaseTableFullWidthRow><div></div></BaseTableFullWidthRow>
 * </BaseTableBody>
 */
export function BaseTableFullWidthRow({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <tr>
      <td colSpan={42}>{children}</td>
    </tr>
  );
}

export function BaseTableRowSkeleton({ className, ...props }: PropsOf<'div'>): JSX.Element {
  return (
    <tr className="py-2">
      <td colSpan={42}>
        <div className={twMerge('pl-8 h-12 w-full animate-pulse rounded-xl bg-secondary-200', className)} {...props} />
      </td>
    </tr>
  );
}

export function BaseTableFooter({ className, ...props }: PropsOf<'tfoot'>): JSX.Element {
  return <tfoot className={twMerge('', className)} {...props} />;
}

export function BaseTablePagination({ className, pagination, ...props }: PropsOf<'tfoot'> & {
  pagination: Pagination
}): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'component.pagination' });
  const { page, setPage, numberOfPages } = pagination;

  return (
    <tfoot className={twMerge('text-sm text-gray-500', className)} {...props}>
    <tr>
      <td colSpan={42} className="py-2">
        <div className="flex items-center justify-end gap-2">
          <button onClick={() => setPage(p => p - 1)} disabled={page === 1} type="button"
                  className="flex items-center justify-center p-1.5 bg-secondary-200 rounded-md text-primary-900 disabled:opacity-25 hover:opacity-75">
            <TbChevronLeft />
          </button>
          <p>{t('pageOf', { page, total: numberOfPages })}</p>
          <button onClick={() => setPage(p => p + 1)} disabled={page === numberOfPages} type="button"
                  className="flex items-center justify-center p-1.5 bg-secondary-200 rounded-md text-primary-900 disabled:opacity-25 hover:opacity-75">
            <TbChevronRight />
          </button>
        </div>
      </td>
    </tr>
    </tfoot>
  );
}
