import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaMinus, FaPlus, FaTrash } from 'react-icons/fa6';
import classNames from 'classnames';
import { FaEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { Transition } from '@headlessui/react';
import { intlFormatDistance } from 'date-fns';
import SearchBar from '../../components/forms/SearchBar';
import Button from '../../components/buttons/Button';
import { ScoreCard, ScoreCardOnB2CConnectionData, ScoreCardWithParticipationData } from '../../../types/lists';
import ScoreCardModal from '../../components/modals/ScoreCardModal';
import {
  createScoreCard,
  deleteScoreCard,
  getAllScoreCards,
  updateScoreCard,
  updateScoreCardScore,
} from '../../../services/listService';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import { RootState } from '../../../redux/reducers';
import { useDebounceEffect } from '../../../utils/useDebounceEffect';
import useIsScreenSize from '../../../hooks/effects/useIsScreenSize';
import Labeled from '../../components/misc/Labeled';
import TabButtons from '../../components/buttons/TabButtons';
import PageHeader from '../../components/misc/PageHeader';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import EmptyScreenView from '../../components/misc/EmptyScreenView';
import WithTooltip from '../../components/misc/WithTooltip';

const modalAnimations = {
  enter: 'transition-all duration-200',
  enterFrom: 'opacity-0 transform translate-x-2 -translate-y-2',
  enterTo: 'opacity-100 transform translate-x-0 translate-y-0',
  leave: 'transition-all duration-200',
  leaveFrom: 'opacity-100 transform translate-x-0 translate-y-0',
  leaveTo: 'opacity-0 transform translate-x-2 -translate-y-2',
};

export default function ScoreListsPage(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score' });

  const [search, setSearch] = useState('');
  const [selectedCard, setSelectedCard] = useState<ScoreCardWithParticipationData>();
  const [cards, setCards] = useState<ScoreCardWithParticipationData[]>([]);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const selectedBusiness = useSelectedBusiness();
  const userId = useSelector((state: RootState) => state.application.userId);
  const isMd = useIsScreenSize('md');

  useEffect(() => {
    getAllScoreCards()
      .then(setCards)
      .catch(() => toast.error(t('fetchError')));
  }, [selectedBusiness]);

  useEffect(() => {
    if (cards && cards.length > 0) {

      const updatedCard = cards?.find(card => card.scoreCardId === selectedCard?.scoreCardId);
      if (updatedCard) {
        setSelectedCard(cards?.find(card => card.scoreCardId === selectedCard?.scoreCardId));
      } else {
        setSelectedCard(undefined);
      }
    }
  }, [cards]);

  const filteredCards = cards.filter(card => card.name.toLowerCase().includes(search.toLowerCase()));

  if (!selectedBusiness) return <></>;

  return (
    <>
      <PageHeader
        title={t('title')} subtitle={t('subtitle')}
        primaryButton={<PrimaryButton text={t('createButton')}
                                      icon={FaPlus} onClick={() => setCreateModalOpen(true)} />}
      />
      <main className="flex gap-4 relative">
        {cards.length === 0
          ? <EmptyScreenView title={t('noCardsTitle')} subtitle={t('noCardsSubtitle')} />
          : (
            <>
              <section className="flex flex-col gap-2 flex-1">
                <div className="flex items-center gap-2">
                  <SearchBar search={search} setSearch={setSearch}
                             inputProps={{ placeholder: t('search') }} />
                </div>
                <div className="flex flex-col gap-2 pt-2">
                  {
                    filteredCards.length === 0
                      ? <p className="text-gray-500">{t('noResults')}</p>
                      : filteredCards.map((card) => (
                        <ScoreCardItem
                          key={`${card.scoreCardId}_small_card`}
                          ownScore={card.scoreCardOnB2CConnection?.find(x => x.b2cConnection.user.userId === userId)?.score}
                          card={card}
                          setCards={setCards}
                          selected={selectedCard?.scoreCardId === card.scoreCardId}
                          onClick={() => selectedCard?.scoreCardId === card.scoreCardId ? setSelectedCard(undefined) : setSelectedCard(card)}
                        />
                      ))}
                </div>
              </section>
              {!isMd ?
                !selectedCard
                  ? <SelectCardPrompt />
                  : <ScoreCardDisplay card={selectedCard} setCards={setCards} />
                : null
              }
            </>
          )
        }
      </main>
      <ScoreCardModal
        open={createModalOpen} setOpen={setCreateModalOpen}
        onSubmit={async (card) => {
          createScoreCard({ ...card, businessId: selectedBusiness?.businessId! })
            .then((newCard) => {
              setCards([...cards, newCard]);
            })
            .catch(() => toast.error(t('createError')));
        }}
      />

    </>
  );
}

function ScoreCardItem({ card, setCards, selected, onClick, ownScore }: {
  card: ScoreCardWithParticipationData,
  setCards: Dispatch<SetStateAction<ScoreCardWithParticipationData[]>>,
  selected: boolean,
  onClick: () => void,
  ownScore?: number,
}): JSX.Element {
  const { t, i18n } = useTranslation('', { keyPrefix: 'page.lists.score' });
  const isMd = useIsScreenSize('md');

  return (
    <div className={classNames(
      'bg-secondary-200 bg-opacity-50 rounded-xl p-4 transition-all',
      'hover:border-2 hover:border-opacity-100', {
        'border-2 border-opacity-100 border-primary-300': selected,
        'border-2 border-opacity-0 border-secondary-200': !selected,
        'cursor-pointer': !isMd,
      })}
         onClick={isMd ? undefined : onClick}
    >
      <>
        <div className="flex items-center justify-between">
          <div className="w-full">
            <h4 className="font-semibold text-lg">{card.name}</h4>
            <p>{t('yourScore')}: {isNumber(ownScore) ? ownScore : '-'}</p>
            <p className="text-right text-gray-500 text-sm">
              {t('lastUpdated')}: {intlFormatDistance(card.lastUpdated, new Date(), { locale: i18n.language })}
            </p>
          </div>
          {isMd &&
            <FaChevronDown className={`cursor-pointer transition-transform ${selected ? 'rotate-180' : 'rotate-0'}`}
                           onClick={onClick} size={20} />}
        </div>
        <Transition
          show={selected && isMd}
          enter="transition-all duration-200"
          enterFrom="opacity-0 transform scale-95 -translate-y-1"
          enterTo="opacity-100 transform scale-100 -translate-y-0"
          leave="transition-all duration-200"
          leaveFrom="opacity-100 transform scale-100 -translate-y-0"
          leaveTo="opacity-0 transform scale-95 -translate-y-1"
        >
          <ScoreCardDisplay card={card} setCards={setCards} mobile />
        </Transition>
      </>
    </div>
  );
}

function ScoreCardDisplay({ card, setCards, mobile = false }: {
  card: ScoreCardWithParticipationData,
  setCards: Dispatch<SetStateAction<ScoreCardWithParticipationData[]>>
  mobile?: boolean
}): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score' });
  const { t: tTooltip } = useTranslation('', { keyPrefix: 'tooltip' });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleScoreChange = (pId: number, newScore: number) => {
    setCards((prev) => prev.map(prevCard => {
      if (prevCard.scoreCardId === card.scoreCardId) {
        const newCard = { ...prevCard };
        newCard.scoreCardOnB2CConnection = newCard.scoreCardOnB2CConnection.map(part => {
          if (part.connectionId === pId) {
            return { ...part, score: newScore };
          }
          return part;
        });
        newCard.lastUpdated = new Date();
        return newCard;
      }
      return prevCard;
    }));
  };

  const handleEditScoreCard = async (submittedCard: { name: string; memberEditAccess: boolean }) => {
    updateScoreCard(card.scoreCardId, submittedCard)
      .then((newCard) => {
        const editedCard: ScoreCardWithParticipationData = { ...card, ...submittedCard, lastUpdated: new Date() };
        setCards((prev) => {
          return prev.map(c => c.scoreCardId === newCard.scoreCardId ? editedCard : c);
        });
      })
      .catch(() => toast.error(t('createError')));
  };

  const handleDeleteScoreCard = async () => {
    deleteScoreCard(card.scoreCardId)
      .then(() => {
        setDeleteModalOpen(false);
        setCards((prev) => prev.filter(prevCard => prevCard.scoreCardId !== card.scoreCardId));
      });
  };

  return (
    <>
      <section
        className={mobile ? '' : 'flex-1 sticky top-20 h-fit bg-secondary-200 bg-opacity-50 rounded-xl p-4 transition-all'}>
        <div className="flex justify-between pb-4">
          <div>
            {!mobile && <h3 className="font-bold text-xl">{card.name}</h3>}
            <div
              className={`text-sm gap-2 px-3 py-2 mt-1 w-fit rounded-xl flex items-center ${card.memberEditAccess ? 'bg-primary-300 text-secondary-50' : 'bg-danger text-secondary-50'}`}>
              {card.memberEditAccess ? t('membersCanEditScores') : t('membersCannotEditScores')}
            </div>
          </div>
          <div className="flex gap-2 relative">
            <WithTooltip className="top-[unset] mt-1" tooltip={tTooltip('edit')}>
              <Button onClick={() => {
                setEditModalOpen(true);
              }} className="aspect-square p-2 rounded-lg bg-primary-300 text-secondary-50 text-lg">
                <FaEdit />
              </Button>
            </WithTooltip>
            <WithTooltip className="top-[unset] mt-1" tooltip={tTooltip('delete')}>
              <Button disabled={editModalOpen} onClick={() => {
                setDeleteModalOpen(true);
              }} className="aspect-square p-2 rounded-lg bg-danger text-secondary-50 text-lg">
                <FaTrash />
              </Button>
            </WithTooltip>
            <Transition
              as={React.Fragment}
              show={deleteModalOpen}
              {...modalAnimations}
            >
              <div
                className="absolute right-0 top-0 z-50 bg-secondary-50 rounded-lg w-80 p-4 flex flex-col gap-2 shadow-md">
                <h4 className="font-semibold">{t('deleteTitle')}</h4>
                <p className="text-sm text-gray-500">{t('deleteDescription', { listName: card.name })}</p>
                <div className="flex items-center gap-2 self-end">
                  <Button onClick={() => setDeleteModalOpen(false)} className="rounded-xl text-secondary-50"
                          variant="secondary">{t('cancelButton')}</Button>
                  <Button onClick={handleDeleteScoreCard} className="rounded-xl"
                          variant="danger-v2">{t('deleteButton')}</Button>
                </div>
              </div>
            </Transition>
            <Transition
              as={React.Fragment}
              show={editModalOpen}
              {...modalAnimations}
            >
              <div
                className="absolute right-10 top-0 z-50 bg-secondary-50 rounded-lg w-[30rem] p-4 flex flex-col gap-2 shadow-md">
                <h4 className="font-semibold text-lg">{t('forms.editTitle')}</h4>
                <ScoreCardEditForm onEdit={handleEditScoreCard} initialState={card} editModalOpen={editModalOpen}
                                   setEditModalOpen={setEditModalOpen} />
              </div>
            </Transition>
          </div>
        </div>
        <div>
          <table className="w-full text-left">
            <thead>
            <tr className="pb-6">
              <th className="w-1/2 pb-2">{t('name')}</th>
              <th className="w-1/2 pb-2 text-right">{t('score')}</th>
            </tr>
            </thead>
            <tbody>
            {card && card.scoreCardOnB2CConnection && card.scoreCardOnB2CConnection.length > 0 && card?.scoreCardOnB2CConnection.map(p => (
              <ScoreRow
                key={`${p.scoreCardId}_${p.connectionId}_list_item`}
                scoreData={p}
                onScoreChange={(newScore) => handleScoreChange(p.connectionId, newScore)}
              />
            ))}
            </tbody>
          </table>
        </div>
      </section>
      {/*<ScoreCardModal*/}
      {/*  open={editModalOpen} setOpen={setEditModalOpen}*/}
      {/*  initialState={{ name: card.name, memberEditAccess: card.memberEditAccess }}*/}
      {/*  onSubmit={handleEditScoreCard}*/}
      {/*/>*/}
    </>
  );
}

function ScoreRow({ scoreData, onScoreChange }: {
  scoreData: ScoreCardOnB2CConnectionData,
  onScoreChange?: (newScore: number) => void
}): JSX.Element {
  const [score, setScore] = useState<string>(scoreData.score.toString());
  const [lastScore, setLastScore] = useState(scoreData.score);
  const [status, setStatus] = useState<'idle' | 'loading' | 'unsavedChanges'>('idle');
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score' });

  useDebounceEffect(() => {
    const parsedScore = Number.parseFloat(score);
    if (Number.isNaN(parsedScore) || parsedScore === lastScore) {
      setStatus('idle');
      return;
    }

    setStatus('loading');
    updateScoreCardScore(scoreData.scoreCardId, scoreData.connectionId, parsedScore)
      .then(() => {
        setLastScore(parsedScore);
        onScoreChange?.(parsedScore);
      })
      .catch(() => {
        setScore(lastScore.toString());
        toast.error(t('updateScoreError'));
      })
      .finally(() => setStatus('idle'));
  }, 1000, [score]);

  useEffect(() => {
    if (Number(score) !== lastScore) setStatus('unsavedChanges');
  }, [score, lastScore]);

  return (
    <tr className="border-b border-primary-900 border-opacity-10 last:border-none">
      <td className="py-2">{scoreData.b2cConnection.user.firstName} {scoreData.b2cConnection.user.lastName}</td>
      <td className="flex items-center justify-end gap-4">
        <div className="flex justify-center items-center gap-2 py-2">
          <button type="button" onClick={() => setScore(prev => (Number(prev) - 1).toString())}
                  className="text-lg h-auto rounded-full bg-danger text-secondary-50 p-1 aspect-square">
            <FaMinus />
          </button>
          <div className="relative text-center">
            <input
              type="number"
              className={`!w-16 !bg-opacity-100 !rounded-xl !bg-secondary-50 !h-8 !text-center ${Number.isNaN(Number.parseFloat(score)) ? '!border-danger !focus:border-danger' : '!border-none !focus:border-none'}`}
              value={score}
              onChange={(ev) => setScore(ev.target.value)}
            />
            {(status !== 'idle') && <div
              className={`absolute top-0.5 -right-2 bg-secondary w-2 h-2 rounded-full ${status === 'loading' ? 'animate-pulse' : ''}`} />}
          </div>
          <button type="button" onClick={() => setScore(prev => (Number(prev) + 1).toString())}
                  className="text-lg rounded-full bg-primary-300 text-secondary-50 p-1 aspect-square">
            <FaPlus />
          </button>
        </div>
        {/*<Button className="text-primary-900 p-0 text-xl"><FaXmark /></Button>*/}
      </td>
    </tr>
  );
}

function SelectCardPrompt(): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score' });

  return (
    <section className="flex-1 sticky top-20 p-4 block">
      <div className="flex flex-col items-center justify-center w-full mt-8 gap-4 opacity-75">
        <img src="/assets/figures/mess.svg" alt="select card" className="w-1/4 max-md:w-1/2" />
        <h3 className="font-serif text-center text-2xl mt-2">{t('selectCardTitle')}</h3>
      </div>
    </section>
  );
}

function ScoreCardEditForm({ onEdit, editModalOpen, setEditModalOpen, initialState }: {
  editModalOpen: boolean,
  setEditModalOpen: Dispatch<SetStateAction<boolean>>,
  initialState: ScoreCard,
  onEdit: (submittedCard: { name: string; memberEditAccess: boolean }) => void
}): JSX.Element {
  const { t } = useTranslation('', { keyPrefix: 'page.lists.score' });
  const [name, setName] = useState(initialState.name);
  const [memberAccess, setMemberAccess] = useState(initialState.memberEditAccess);

  return (
    <>
      <Labeled label={t('forms.name')}>
        <input
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          type="text" placeholder={t('forms.namePlaceholder')}
        />
      </Labeled>
      <TabButtons initialState={initialState.memberEditAccess} containerClassName="bg-secondary-200"
                  highlightClassName="bg-secondary-50" buttonClassName="px-2" buttonSelectedClassName="px-2" options={[
        { label: t('membersCanEdit'), value: true as const },
        { label: t('membersCannotEdit'), value: false as const },
      ]} onTabChange={setMemberAccess} />
      <div className="flex items-center gap-2 self-end">
        <Button onClick={() => setEditModalOpen(false)} className="rounded-xl"
                variant="danger-v2">{t('cancelButton')}</Button>
        <Button onClick={() => {
          onEdit({ name, memberEditAccess: memberAccess });
          setEditModalOpen(false);
        }} className="rounded-xl text-secondary-50"
                variant="secondary">{t('forms.editButton')}</Button>
      </div>
    </>
  );
}
