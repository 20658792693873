import React, { HTMLAttributes } from 'react';
import { COLOR_SECONDARY_50 } from '../../../constants';

type VeraPhoneProps = HTMLAttributes<SVGElement> & {
  color?: string;
};

export default function VeraPhone({ color = COLOR_SECONDARY_50, ...props }: VeraPhoneProps) {
  return (
    <svg
      width="156"
      height="202"
      viewBox="0 0 156 202"
      fill="none"
      {...props}>
      <path
        d="M64.6618 200.184L11.2742 175.334C1.53019 170.799 -2.70846 159.188 1.80701 149.401L65.5179 11.3242C70.0334 1.53641 81.5931 -2.72079 91.3382 1.81563L144.726 26.666C154.47 31.2013 158.708 42.8116 154.193 52.5994L90.4821 190.676C85.9666 200.464 74.4069 204.721 64.6618 200.184Z"
        fill={color}
      />
      <path
        d="M23.4319 51.139L86.7274 46.6575C90.046 46.4227 92.833 49.1097 92.7516 52.4498C92.4864 63.2985 88.0896 84.7889 57.7491 86.9364C27.4099 89.0844 20.054 68.4284 18.2755 57.7235C17.7288 54.4277 20.1121 51.3739 23.4319 51.139Z"
        fill="#081647"
      />
      <path
        d="M40.3819 30.0002C40.6006 33.1289 38.2533 35.8451 35.1383 36.0648C32.0233 36.2856 29.32 33.928 29.1002 30.7993C28.8804 27.6707 31.2277 24.9556 34.3427 24.7348C37.4589 24.514 40.1621 26.8716 40.3819 30.0002Z"
        fill="#081647"
      />
      <path
        d="M78.2723 27.3176C78.4922 30.4463 76.1449 33.1614 73.0299 33.3822C69.9137 33.603 67.2105 31.2454 66.9907 28.1167C66.772 24.9869 69.1193 22.2718 72.2343 22.051C75.3493 21.8314 78.0525 24.189 78.2723 27.3176Z"
        fill="#081647"
      />
      <path
        d="M52.5858 174.408C50.8038 178.269 46.2418 179.95 42.3975 178.16C38.5521 176.37 36.8806 171.789 38.6626 167.927C40.4434 164.066 45.0054 162.385 48.8497 164.175C52.6952 165.965 54.3678 170.546 52.5858 174.408Z"
        fill="#081647"
      />
    </svg>
  );
}
