import { JSXElementConstructor, ReactNode, Children, isValidElement, ReactElement, cloneElement } from 'react';

export function findReactElement<P = any>(
  children: ReactNode,
  type: JSXElementConstructor<any>,
): ReactElement<P> | undefined {
  const traverseChildren = (cs: ReactNode): ReactElement<P> | undefined => 
    Children.toArray(cs)
      .map((child) => {
        if (isValidElement(child)) {
          if (child.type === type) {
            return child as ReactElement<P>;
          }

          if (child.props?.children) {
            return traverseChildren(child.props.children);
          }
        }
        return undefined;
      })
      .find(Boolean);

  return traverseChildren(children);
}

export function replaceReactElement<P = any>(
  children: ReactNode,
  element: ReactElement,
  replacement: ReactElement<P>,
): ReactNode {
  const traverseChildren = (cs: ReactNode): ReactNode =>
    Children.map(cs, (child) => {
      if (isValidElement(child)) {
        if (child.type === element.type) {
          return replacement;
        }

        if (child.props?.children) {
          return cloneElement(child as ReactElement, {
            children: traverseChildren(child.props.children),
          });
        }
      }
      return child;
    });

  return traverseChildren(children);
}