import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import React from 'react';
import Button from '../buttons/Button';

interface StepFlowProps {
  indexState: [number, React.Dispatch<React.SetStateAction<number>>];
  steps: React.ReactElement[];
}

export default function StepFlow({
  indexState: [index, setIndex],
  steps,
}: StepFlowProps): JSX.Element {

  return (
    <div className="flex h-full w-full flex-1 flex-col items-center gap-6 rounded-[25px] bg-opacity-50 p-10">
      <div className="mx-auto flex h-2 w-full max-w-xl gap-6">
        {steps.map((s, i) => (
          <div
            key={i}
            onClick={index > i ? () => setIndex(i) : undefined}
            className={`h-2 w-full rounded-[5px] bg-primary-900 transition-all ${i > index ? 'bg-opacity-15' : 'cursor-pointer'}`}
          />
        ))}
      </div>
      {steps[index]}
    </div>
  );
}

interface StepFlowStepProps {
  children: React.ReactNode;
  setStep: (i: 1 | -1) => void;
  showPrev?: boolean;
  showNext?: boolean;
  onSubmit?: () => void;
  disabled?: boolean;
  submitText?: string;
  title?: string;
  subtitle?: string;
  className?: string;
}

export function StepFlowStep({
  children,
  setStep,
  onSubmit,
  disabled = false,
  showPrev = true,
  submitText,
  showNext = true,
  title,
  className,
  subtitle,
}: StepFlowStepProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      <div className={twMerge('flex w-full flex-col gap-4 max-w-xl', className)}>
        {(title || subtitle) && (
          <div className="flex flex-col">
            {title && <h1 className="text-[30px] font-semibold leading-[35px]">{title}</h1>}
            {subtitle && <p className="text-[16px] text-opacity-50">{subtitle}</p>}
          </div>
        )}
        {children}
      </div>
      <div className="ml-auto mt-auto flex gap-4">
        {showPrev && (
          <Button variant="secondary" onClick={() => setStep(-1)}>
            {t('general.back')}
          </Button>
        )}
        {showNext && (
          <Button variant="primary" disabled={disabled} onClick={() => setStep(1)}>
            {t('general.continue')}
          </Button>
        )}
        {onSubmit && (
          <Button variant="primary" disabled={disabled} onClick={onSubmit}>
            {submitText ?? t('general.create')}
          </Button>
        )}
      </div>
    </>
  );
}
