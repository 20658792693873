import React, { createElement, useEffect, useLayoutEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addDays, format, isSameDay } from 'date-fns';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { HiTrash } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { CgSpinner } from 'react-icons/cg';
import {
  AgendaPeriod,
  CommunicationType,
  EmailType,
  EmailTemplate,
  PostcardFinish,
  PostcardOrder,
  ScheduledEmail,
  ScheduledNotification,
} from '../../../types/communication';
import Labeled from '../../components/misc/Labeled';
import Button from '../../components/buttons/Button';
import DatePicker from '../../components/forms/DatePicker';
import connectionService from '../../../services/connectionService';
import { RootState } from '../../../redux/reducers';
import { Connection, ConnectionType } from '../../../redux/slices/connectionsSlice';
import communicationsService from '../../../services/communicationsService';
import {
  CustomPostcardTemplate,
  Discount,
  PostcardFormat,
  PostcardTemplate,
} from '../../../types/misc';
import orderService from '../../../services/orderService';
import { postcardTemplateCategoryIcon } from '../../../utils/iconUtils';
import Icon from '../../components/icons/Icon';
import AddressPicker from '../../components/forms/AddressPicker';
import {
  getExpectedDeliveryDate,
  getFirstPotentialDeliveryDate,
  getSendDateFromDeliveryDate,
} from '../../../utils/postcardUtils';
import CheckoutForm from '../../components/forms/CheckoutForm';
import {
  COLOR_PRIMARY_300,
  COLOR_SECONDARY,
  COLOR_SECONDARY_50,
  stripeAppearance,
} from '../../../constants';
import ApiImage from '../../components/misc/ApiImage';
import UploadPostcardModal, { UploadData } from '../../components/modals/uploadPostcardModal';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import DiscountInput from '../../components/misc/DiscountInput';
import useActiveSubscription from '../../../hooks/business/useActiveSubscription';
import { setTemplatesLoading } from '../../../redux/slices/communicationsSlice';
import { hasRequiredSubscriptionForCommunication } from '../../../utils/subscriptionUtils';
import ParticipantsPicker from '../../components/misc/ParticipantsPicker';
import VeraPhone from '../../components/icons/VeraPhone';
import VeraAgenda from '../../components/icons/VeraAgenda';
import DropdownSelect from '../../components/forms/DropdownSelect';
import Slider from '../../components/misc/Slider';
import { Frequency } from '../../../types/event';

const STRIPE_KEY: string = process.env.REACT_APP_STRIPE_KEY as string;
const stripePromise = loadStripe(STRIPE_KEY);

type CommunicationInput = Partial<ScheduledEmail | ScheduledNotification | PostcardOrder> & {
  sendDirect?: boolean;
  templateCategory?: string;
  templateName?: string;
  desiredDeliveryDate?: Date;
  clientSecret?: string;
  isRecurring?: boolean;
};

export default function CreateCommunicationPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const { type, communication: communicationState, selectedConnections } = location.state ?? {};
  const [communication, setCommunication] = useState<CommunicationInput>(communicationState ?? {});
  const [templates, setTemplates] = useState<PostcardTemplate[]>([]);
  const [index, setIndex] = useState<number>(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = useActiveSubscription();

  useEffect(() => {
    if (!hasRequiredSubscriptionForCommunication(type, subscription)) {
      navigate('/communication');
    }
  }, [subscription]);

  useEffect(() => {
    if (!type) navigate('/communication');

    if (selectedConnections) {
      if (type === CommunicationType.NOTIFICATION) {
        connectionService.getConnectionsWithPushToken().then((validConnectionIds) => {
          setCommunication((prev) => ({
            ...prev,
            connectionIds: (selectedConnections as Connection[])
              .map((x) => +x.id)
              .filter((id) => validConnectionIds.includes(id)),
            localContactIds: [],
          }));
        });
      } else
        setCommunication((prev) => ({
          ...prev,
          connectionIds: (selectedConnections as Connection[])
            .filter((x) => x.type === ConnectionType.B2C_CONNECTION)
            .map((x) => +x.id),
          localContactIds: (selectedConnections as Connection[])
            .filter((x) => x.type === ConnectionType.LOCAL_CONTACT)
            .map((x) => +x.id),
        }));
    }

    if (type === CommunicationType.POSTCARD) {
      dispatch(setTemplatesLoading(true));
      orderService
        .getAllPostcardTemplates()
        .then((temps) => {
          setTemplates(temps);
          if (communicationState) {
            const template = temps.find((temp) => temp.id === communicationState.template);
            if (template)
              setCommunication({
                ...communicationState,
                templateName: template.name,
                templateCategory: template.labels.filter((l) => !l.includes('price'))[0],
                desiredDeliveryDate: addDays(new Date(communicationState.sendDate), 1),
              });
          }
        })
        .finally(() => dispatch(setTemplatesLoading(false)));
    }
  }, []);

  const handleEmailSubmit = async () => {
    const email = sanitizeInput(communication) as ScheduledEmail;

    if (isUpdate(email)) await communicationsService.updateScheduledEmail(email);
    else if (communication.sendDirect) {
      await communicationsService.sendEmail(email, communication.isRecurring);
    } else await communicationsService.createScheduledEmail(email, communication.isRecurring);
    navigate('/communication');
  };

  const handleNotificationSubmit = async () => {
    const notification = sanitizeInput(communication, ['localContactIds']) as ScheduledNotification;

    if (isUpdate(notification))
      await communicationsService.updateScheduledNotification(notification);
    else if (communication.sendDirect) {
      await connectionService.sendPushNotification(
        notification.connectionIds.map((id) => id.toString()),
        notification.message,
      );
    } else await communicationsService.createScheduledNotification(notification);
    navigate('/communication');
  };

  const handlePostcardSubmit = async () => {
    if (!isUpdate(communication)) return;
    await orderService.updatePostcardcardOrder(sanitizeInput(communication) as PostcardOrder);
    navigate('/communication');
  };

  const disabled = nextDisabled(communication, index, type);
  const steps = getSteps({
    communication,
    setCommunication,
    communicationType: type,
    templates,
    setTemplates,
    setIndex: (i) => setIndex(index + i),
  });

  return (
    <>
      <div className="flex h-full w-full flex-1 flex-col items-center gap-6 rounded-[25px] bg-opacity-50 p-10">
        <div className="mx-auto flex h-2 w-full max-w-xl gap-6">
          {steps.map((s, i) => (
            <div
              key={i}
              onClick={index > i ? () => setIndex(i) : undefined}
              className={`h-2 w-full rounded-[5px] bg-primary-900 transition-all ${i > index ? 'bg-opacity-15' : 'cursor-pointer'}`}
            />
          ))}
        </div>

        <div className="flex h-full w-full flex-col justify-center gap-6 md:flex-row">
          <OverviewPanel
            index={index}
            communication={communication}
            communicationType={type}
            templates={templates}
            setCommunication={setCommunication}
          />
          <div className="no-scrollbar flex w-full max-w-xl flex-col items-center overflow-y-scroll">
            {steps[index]}
          </div>
        </div>

        <div className="ml-auto mt-auto flex gap-4">
          {index > 0 && (type !== CommunicationType.POSTCARD || index !== steps.length - 1) && (
            <Button variant="secondary" onClick={() => setIndex(index - 1)}>
              {t('general.back')}
            </Button>
          )}
          {index < steps.length - 1 ? (
            <Button variant="primary" disabled={disabled} onClick={() => setIndex(index + 1)}>
              {t('general.continue')}
            </Button>
          ) : (
            (type !== CommunicationType.POSTCARD || isUpdate(communication)) && (
              <Button
                variant="primary"
                disabled={disabled}
                onClick={() => {
                  switch (type) {
                    case CommunicationType.EMAIL:
                      handleEmailSubmit();
                      break;
                    case CommunicationType.NOTIFICATION:
                      handleNotificationSubmit();
                      break;
                    default:
                      handlePostcardSubmit();
                      break;
                  }
                }}>
                {isUpdate(communication) ? t('general.update') : t('general.create')}
              </Button>
            )
          )}
        </div>
      </div>
    </>
  );
}

function sanitizeInput(
  communication: CommunicationInput,
  additional: string[] = [],
): CommunicationInput {
  return _.omit(
    communication,
    'sendDirect',
    'templateCategory',
    'templateName',
    'desiredDeliveryDate',
    'isRecurring',
    ...additional,
  );
}

function getSteps(props: PostcardPanelProps) {
  const base = [<SendDatePanel {...props} />, <SendeesPanel {...props} />];

  switch (props.communicationType) {
    case CommunicationType.POSTCARD:
      if (isUpdate(props.communication))
        return [
          <PostcardTypePanel {...props} />,
          <PostcardSelectionPanel {...props} />,
          <MessageContentPanel {...props} />,
          <PostcardSendDetailsPanel {...props} />,
        ];
      return [
        <PostcardTypePanel {...props} />,
        <PostcardSelectionPanel {...props} />,
        <MessageContentPanel {...props} />,
        <PostcardFinishPanel {...props} />,
        <PostcardSendDetailsPanel {...props} />,
        <SendeesPanel {...props} />,
        <PostcardPaymentPanel {...props} />,
      ];
    case CommunicationType.EMAIL:
      return [
        <EmailTypePanel {...props} />,
        <EmailTemplatePanel {...props} />,
        <EmailContentPanel {...props} />,
        ...base,
      ];
    case CommunicationType.NOTIFICATION:
      return [<MessageContentPanel {...props} />, ...base];
    default:
      return [];
  }
}

function nextDisabled(communication: CommunicationInput, index: number, type: CommunicationType) {
  switch (type) {
    case CommunicationType.EMAIL:
      const email = communication as ScheduledEmail;
      switch (index) {
        case 0:
          return !email.type;
        case 1:
          return !email.template;
        case 2:
          return (
            !email.title ||
            !email.body ||
            (email.type === EmailType.AGENDA && (!email.footer || !email.period))
          );
        case 3:
           return (
                (!communication.sendDirect && !communication.sendDate) ||
                (email.type === EmailType.AGENDA &&
                  communication.isRecurring &&
                  (!email.frequency || !email.endDate))
              );
          case 4:
            return (
                  (communication.connectionIds?.length === 0 && email.localContactIds?.length === 0) ||
                  !communication.connectionIds ||
                  !email.localContactIds
                );
        default:
          return false;
      }
    case CommunicationType.NOTIFICATION:
      switch (index) {
        case 0:
          return !(communication as ScheduledNotification).message;
        case 1:
          return !communication.sendDirect && !communication.sendDate;
        case 2:
          return !communication.connectionIds?.length;
        default:
          return false;
      }
    case CommunicationType.POSTCARD:
      const postcard = communication as PostcardOrder;
      switch (index) {
        case 0:
          return !communication.templateCategory;
        case 1:
          return !communication.templateName;
        case 2:
          return !postcard.message;
        case 3:
          return !postcard.template || !postcard.finish;
        case 4:
          return !communication.sendDate || !postcard.senderAddress;
        case 5:
          return (
            (communication.connectionIds?.length === 0 && postcard.localContactIds?.length === 0) ||
            !communication.connectionIds ||
            !postcard.localContactIds
          );
        default:
          return false;
      }
    default:
      return false;
  }
}

interface PanelProps {
  communication: CommunicationInput;
  setCommunication: (content: CommunicationInput) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  communicationType: CommunicationType;
}

export function SendDatePanel({ communication, setCommunication }: PanelProps) {
  const { t } = useTranslation();
  const email = communication as ScheduledEmail;

  return (
    <div className="flex w-full flex-col gap-4">
      <h1 className="text-[30px] font-semibold">{t('page.createCommunication.sendDate.title')}</h1>

      {!isUpdate(communication) && (
        <div className="flex gap-4">
          {/* Send Direct */}
          <div
            className={`flex w-full cursor-pointer justify-between gap-2 rounded-[10px] border-2 bg-secondary-200 bg-opacity-50 p-4 ${communication.sendDirect ? 'border-primary-300' : 'border-secondary-200'}`}
            onClick={() => setCommunication({ ...communication, sendDirect: true })}>
            <div className="flex flex-col gap-2">
              <h2 className="text-[17px] font-semibold">
                {t('page.createCommunication.sendDate.direct.title')}
              </h2>
              <p className="text-[10px] text-opacity-50">
                {t('page.createCommunication.sendDate.direct.description')}
              </p>
            </div>
            <input type="checkbox" checked={communication.sendDirect} />
          </div>

          {/* Send Date */}
          <div
            className={`flex w-full cursor-pointer justify-between gap-2 rounded-[10px] border-2 bg-secondary-200 bg-opacity-50 p-4 ${!communication.sendDirect ? 'border-primary-300' : 'border-secondary-200'}`}
            onClick={() => setCommunication({ ...communication, sendDirect: false })}>
            <div className="flex flex-col gap-2">
              <h2 className="text-[17px] font-semibold">
                {t('page.createCommunication.sendDate.scheduled.title')}
              </h2>
              <p className="text-[10px] text-opacity-50">
                {t('page.createCommunication.sendDate.scheduled.description')}
              </p>
            </div>
            <input type="checkbox" checked={!communication.sendDirect} />
          </div>
        </div>
      )}

      {email.type === EmailType.AGENDA && !isUpdate(communication) && (
        <Slider
          label={t('page.createCommunication.sendDate.recurring')}
          items={[
            { label: t('general.no'), value: false },
            { label: t('general.yes'), value: true },
          ]}
          value={communication.isRecurring}
          onChange={(isRecurring) => {
            setCommunication({
              ...communication,
              isRecurring,
              frequency: undefined,
              endDate: undefined,
            } as CommunicationInput);
          }}
        />
      )}

      {!communication.sendDirect && (
        <Labeled label={t('page.createCommunication.sendDate.sendDate')}>
          <DatePicker
            className="w-full md:w-1/2"
            minDateTime={dayjs(new Date())}
            date={communication.sendDate}
            setDate={(date) => setCommunication({ ...communication, sendDate: date })}
          />
        </Labeled>
      )}

      {email.type === EmailType.AGENDA && communication.isRecurring && !isUpdate(communication) && (
        <div className="mb-32 flex w-full gap-4">
          <Labeled label={t('page.createCommunication.sendDate.frequency.title')}>
            <DropdownSelect
              options={Object.values(_.omit(Frequency, Frequency.DAILY, Frequency.YEARLY)).map(
                (type) => ({
                  label: t(`page.createCommunication.sendDate.frequency.${type.toLowerCase()}`),
                  value: type,
                }),
              )}
              value={email.frequency}
              setValue={(v) => setCommunication({ ...email, frequency: v })}
            />
          </Labeled>
          <Labeled label={t('page.createCommunication.sendDate.endDate')}>
            <DatePicker
              className="w-full"
              minDateTime={dayjs(new Date())}
              date={email.endDate}
              setDate={(date) => setCommunication({ ...email, endDate: date })}
            />
          </Labeled>
        </div>
      )}
    </div>
  );
}

export function SendeesPanel({ communication, setCommunication, communicationType }: PanelProps) {
  const [connectionsWithNotifications, setConnectionsWithNotifications] = useState<number[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    connectionService.getConnections();
    if (communicationType === CommunicationType.NOTIFICATION)
      connectionService.getConnectionsWithPushToken().then(setConnectionsWithNotifications);
  }, []);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {t(`page.createCommunication.recipients.${communicationType.toLowerCase()}.title`)}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {t(`page.createCommunication.recipients.${communicationType.toLowerCase()}.description`)}
        </p>
      </div>
      <ParticipantsPicker
        selected={[
          ...(communication.connectionIds ?? []).map((id) => ({
            id: id as unknown as string,
            type: ConnectionType.B2C_CONNECTION,
          })),
          ...((communication as ScheduledEmail).localContactIds ?? []).map((id) => ({
            id: id as unknown as string,
            type: ConnectionType.LOCAL_CONTACT,
          })),
        ]}
        setSelected={(cs) => {
          const [localContactIds, connectionIds] = _.partition(
            cs,
            (c) => c.type === ConnectionType.LOCAL_CONTACT,
          ).map((cIds) => cIds.map((c) => +c.id));

          setCommunication({ ...communication, localContactIds, connectionIds });
        }}
        disable={(c) => {
          switch (communicationType) {
            case CommunicationType.EMAIL:
              return !c.fields.EMAIL;
            case CommunicationType.NOTIFICATION:
              return !connectionsWithNotifications.includes(+c.id);
            case CommunicationType.POSTCARD:
              return !c.fields.ADDRESS;
            default:
              return false;
          }
        }}
        disabledMessage={t(
          `page.createCommunication.recipients.${communicationType.toLowerCase()}.disabledMessage`,
        )}
      />
    </div>
  );
}

function isUpdate(communication: CommunicationInput): boolean {
  return (
    !!(communication as ScheduledEmail).emailId ||
    !!(communication as ScheduledNotification).notificationId ||
    !!(communication as PostcardOrder).orderId
  );
}

export function MessageContentPanel({
  communication,
  setCommunication,
  communicationType,
}: PanelProps) {
  const { t } = useTranslation();
  const input = communication as Partial<ScheduledNotification | PostcardOrder>;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {t(`page.createCommunication.content.${communicationType.toLocaleLowerCase()}.title`)}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {t(
            `page.createCommunication.content.${communicationType.toLocaleLowerCase()}.description`,
          )}
        </p>
      </div>
      <Labeled label={t('page.createCommunication.content.title')}>
        <div className="relative">
          <textarea
            value={input.message}
            onChange={(e) =>
              setCommunication({
                ...input,
                message:
                  communicationType === CommunicationType.POSTCARD
                    ? e.target.value.slice(0, 256)
                    : e.target.value,
              })
            }
            className="h-44 resize-none"
          />
          {communicationType === CommunicationType.POSTCARD && (
            <div className="absolute bottom-2 right-2 text-sm text-primary-900">
              {input.message?.length ?? 0}/{256}
            </div>
          )}
        </div>
        {communicationType === CommunicationType.POSTCARD && (
          <div className="flex gap-2">
            <button
              className="rounded-[20px] bg-secondary-200 p-2 text-sm"
              type="button"
              onClick={() => {
                setCommunication({
                  ...communication,
                  message: `${input.message ?? ''}{ ${t('general.firstName')} }`,
                });
              }}>
              {`{ ${t('general.firstName')} }`}
            </button>
            <button
              className="rounded-[20px] bg-secondary-200 p-2 text-sm"
              type="button"
              onClick={() => {
                setCommunication({
                  ...communication,
                  message: `${input.message ?? ''}{ ${t('general.lastName')} }`,
                });
              }}>
              {`{ ${t('general.lastName')} }`}
            </button>
          </div>
        )}
      </Labeled>
    </div>
  );
}

// EMAIL
function EmailTypePanel({ communication, setCommunication }: PanelProps) {
  const { t } = useTranslation();
  const email = communication as Partial<ScheduledEmail>;

  const content = {
    [EmailType.BASIC]: {
      color: COLOR_PRIMARY_300,
      className: 'bg-primary-300',
      icon: VeraPhone,
    },
    [EmailType.AGENDA]: {
      color: COLOR_SECONDARY,
      className: 'bg-secondary',
      icon: VeraAgenda,
    },
  };

  return (
    <div className="flex w-full flex-col gap-4">
      <h1 className="text-[30px] font-semibold leading-[35px]">
        {t('page.createCommunication.emailType.title')}
      </h1>

      <div className="flex flex-wrap gap-4">
        {Object.values(EmailType).map((type) => (
          <div
            className="flex w-[195px] cursor-pointer flex-col overflow-hidden rounded-[12px] border-2"
            style={{ borderColor: content[type].color }}
            onClick={() =>
              setCommunication({ ...communication, type, period: undefined } as CommunicationInput)
            }>
            <div
              className="flex h-[120px] items-center justify-center bg-opacity-50 px-6 py-4 transition-all"
              style={{
                backgroundColor:
                  type === email.type ? COLOR_SECONDARY_50 : `${content[type].color}50`,
              }}>
              {createElement(content[type].icon, {
                color: type === email.type ? content[type].color : COLOR_SECONDARY_50,
                className: 'h-full transition-all',
              })}
            </div>
            <div
              className="mt-auto flex flex-col px-4 pb-4 pt-2"
              style={{ backgroundColor: content[type].color }}>
              <h2 className="text-[15px] font-semibold text-secondary-50">
                {t(`page.createCommunication.emailType.${type.toLowerCase()}.title`)}
              </h2>
              <p className="text-[10px] font-medium text-secondary-50">
                {t(`page.createCommunication.emailType.${type.toLowerCase()}.description`)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function EmailContentPanel({ communication, setCommunication }: PanelProps) {
  const { t } = useTranslation();
  const email = communication as Partial<ScheduledEmail>;

  return (
    <div className="mb-10 flex w-full flex-col gap-4">
      <h1 className="text-[30px] font-semibold leading-[35px]">
        {t('page.createCommunication.content.title')}
      </h1>
      <Labeled label={t('page.createCommunication.content.titleEmail')}>
        <input
          type="text"
          value={email.title}
          onChange={(e) => setCommunication({ ...email, title: e.target.value })}
        />
      </Labeled>
      <Labeled label={t('page.createCommunication.content.body')}>
        <textarea
          value={email.body}
          onChange={(e) => setCommunication({ ...email, body: e.target.value })}
          className="h-44 resize-none"
        />
      </Labeled>
      {email.type === EmailType.AGENDA && (
        <>
          <Labeled label={t('page.createCommunication.content.period.title')}>
            <DropdownSelect
              className="sm:w-1/2"
              options={Object.values(AgendaPeriod).map((type) => ({
                label: t(`page.createCommunication.content.period.${type.toLowerCase()}`),
                value: type,
              }))}
              value={email.period}
              setValue={(v) => setCommunication({ ...email, period: v })}
            />
          </Labeled>
          <Labeled label={t('page.createCommunication.content.footer')}>
            <input
              type="text"
              value={email.footer}
              onChange={(e) => setCommunication({ ...email, footer: e.target.value })}
            />
          </Labeled>
        </>
      )}
    </div>
  );
}

function EmailTemplatePanel({ communication, setCommunication }: PanelProps) {
  const { t } = useTranslation();
  const email = communication as Partial<ScheduledEmail>;

  return (
    <div className="flex w-full flex-col gap-4">
      <h1 className="text-[30px] font-semibold leading-[35px]">
        {t('page.createCommunication.emailTemplate.title')}
      </h1>

      <div className="flex flex-wrap gap-4">
        {Object.values(EmailTemplate).map((template) => (
          <div
            className="flex w-[195px] cursor-pointer flex-col overflow-hidden rounded-[12px] border-2 border-primary-300"
            onClick={() => setCommunication({ ...communication, template })}>
            <div
              className={`h-[120px] px-6 pt-4 transition-all ${template === email.template ? 'bg-secondary-50' : 'bg-primary-300 bg-opacity-50'}`}>
              <div
                className={`mt-auto flex h-full flex-1 flex-col gap-3 rounded-t-[10px] px-2 py-4 transition-all ${template === email.template ? 'bg-primary-300 bg-opacity-50' : 'bg-secondary-50'}`}>
                <div className="h-[10px] w-1/2 rounded-[3px] bg-primary-900 bg-opacity-50" />
                <div className="flex w-full flex-col gap-1">
                  <div className="h-[8px] w-full rounded-[3px] bg-primary-900 bg-opacity-50" />
                  <div className="h-[8px] w-full rounded-[3px] bg-primary-900 bg-opacity-50" />
                  <div className="h-[8px] w-full rounded-[3px] bg-primary-900 bg-opacity-50" />
                </div>
                <div className="h-[8px] w-1/3 rounded-[3px] bg-primary-900 bg-opacity-50" />
              </div>
            </div>
            <div className="mt-auto flex flex-col bg-primary-300 px-4 pb-4 pt-2">
              <h2 className="text-[15px] font-semibold text-secondary-50">
                {t(`page.createCommunication.emailTemplate.${template.toLowerCase()}.title`)}
              </h2>
              <p className="text-[10px] font-medium text-secondary-50">
                {t(`page.createCommunication.emailTemplate.${template.toLowerCase()}.description`)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// POSTCARD
type PostcardPanelProps = PanelProps & {
  templates: PostcardTemplate[];
  // eslint-disable-next-line react/no-unused-prop-types
  setTemplates: (templates: PostcardTemplate[]) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  setIndex: (index: 1 | -1) => void;
};

function PostcardTypePanel({ communication, setCommunication, templates }: PostcardPanelProps) {
  const { t } = useTranslation();
  const categories = _.uniq(templates.flatMap((template) => template.labels)).filter(
    (l) => !l.includes('price:') && !l.includes('community'),
  );
  const templatesLoading = useSelector((state: RootState) => state.communications.templatesLoading);

  categories.unshift('community');

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {t('page.createCommunication.category.title')}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {t('page.createCommunication.category.description')}
        </p>
      </div>
      <div className="flex flex-wrap gap-4">
        {categories.map((c) => (
          <div
            key={c}
            className={`flex h-40 w-40 cursor-pointer flex-col items-center justify-center gap-2 rounded-[20px] border-2 p-4 transition-all ${communication.templateCategory === c ? 'border-primary-300 bg-secondary-200' : 'border-secondary-200 bg-secondary-50'}`}
            onClick={() =>
              setCommunication({
                ...communication,
                templateCategory: c,
                ...(!isUpdate(communication) ? { templateName: undefined } : {}),
              })
            }>
            <Icon
              icon={postcardTemplateCategoryIcon(c)}
              className={`h-10 w-10 gap-2 transition-all ${communication.templateCategory === c ? 'text-secondary-50' : 'text-secondary-200'}`}
            />
            <p className="text-[17px] font-medium">
              {t(`page.createCommunication.postcard.type.${c}`)}
            </p>
          </div>
        ))}
        {templatesLoading && (
          <div className="flex h-40 w-40 items-center justify-center">
            <CgSpinner color={COLOR_PRIMARY_300} size={60} className="animate-spin" />
          </div>
        )}
      </div>
    </div>
  );
}

function PostcardSelectionPanel({
  communication,
  setCommunication,
  templates,
  setTemplates,
}: PostcardPanelProps) {
  const postcard = communication as Partial<PostcardOrder>;
  const categoryTemplates = _.uniqBy(templates, (t) => t.name).filter((t) =>
    t.labels.includes(communication.templateCategory!),
  );
  const { businessId } = useSelectedBusiness() || {};
  const { t: trans } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isUpdate(communication)) return;
    const template = templates.find((t) => t.id === postcard.template);
    if (template)
      setCommunication({
        ...postcard,
        template: templates.find(
          (t) => t.name === communication.templateName && t.format === template.format,
        )?.id,
      });
  }, [communication.templateName]);

  const handleUpload = async (data: UploadData) => {
    if (!data.imgFile) return;
    await orderService.uploadCustomPostcard(data.imgFile, businessId!);
    orderService.getAllPostcardTemplates().then(setTemplates);
  };

  return (
    <>
      <div className="flex w-full flex-wrap gap-4">
        <div className="flex flex-col">
          <h1 className="text-[30px] font-semibold leading-[35px]">
            {trans('page.createCommunication.template.title')}
          </h1>
          <p className="text-[16px] text-opacity-50">
            {trans('page.createCommunication.template.description')}
          </p>
        </div>
        <div className="flex flex-wrap gap-4">
          {categoryTemplates.map((t) => (
            <div className="relative">
              <ApiImage
                key={t.id}
                alt={t.name}
                path={t.thumbnail}
                className={`flex h-40 w-40 cursor-pointer rounded-[20px] border-4 transition-all ${communication.templateName === t.name ? 'border-primary-300 shadow-lg' : 'border-secondary-200'}`}
                onClick={() =>
                  setCommunication({ ...communication, templateName: t.name, fileName: t.fileName })
                }
              />
              {t.thumbnailId && (
                <button
                  type="button"
                  onClick={() =>
                    orderService
                      .deleteCustomPostcardImage(+t.thumbnailId!)
                      .then(() =>
                        setTemplates(
                          templates.filter((temp) => temp.thumbnailId !== t.thumbnailId),
                        ),
                      )
                  }
                  className="absolute -right-2 -top-2 rounded-full bg-secondary-200 p-1">
                  <HiTrash className="h-4 w-4 text-primary-900" />
                </button>
              )}
            </div>
          ))}
          {communication.templateCategory === 'community' && (
            <div
              onClick={() => setShowModal(true)}
              className="group flex h-40 w-40 cursor-pointer items-center rounded-[20px] border-2 border-dashed border-primary-900 hover:border-opacity-80">
              <Button variant="primary" className="mx-auto group-hover:bg-opacity-80">
                Upload
              </Button>
            </div>
          )}
        </div>
      </div>
      <UploadPostcardModal
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={handleUpload}
      />
    </>
  );
}

function PostcardFinishPanel({ communication, setCommunication, templates }: PostcardPanelProps) {
  const postcard = communication as Partial<PostcardOrder>;
  const { t: trans } = useTranslation();
  const [[double], [single]] = _.partition(
    templates.filter((t) => t.name === communication.templateName),
    (t) => t.format === PostcardFormat.GREETINGCARD_SQ14,
  );

  const [singleId, doubleId] = communication.templateName?.includes('custom')
    ? [CustomPostcardTemplate.IDSingle, CustomPostcardTemplate.IDDouble]
    : [single?.id, double?.id];

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {trans('page.createCommunication.finish.title')}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {trans('page.createCommunication.finish.description')}
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <Labeled label={trans('page.createCommunication.finish.size')}>
          <div className="flex gap-4">
            <div className="flex flex-col items-center gap-1">
              <div
                onClick={() => setCommunication({ ...communication, template: singleId })}
                className={`bg-opacity flex h-fit w-fit cursor-pointer items-center justify-center rounded-[20px] border-2 bg-secondary-200 bg-opacity-50 p-4 transition-all ${postcard.template === singleId ? 'border-primary-300' : 'border-secondary-200'}`}>
                <div className="h-14 w-14 bg-primary-300" />
              </div>
              <p>{trans('page.createCommunication.finish.single')}</p>
              {postcard.template === doubleId && (
                <div className="rounded-[6px] bg-secondary p-1 text-[13px] text-secondary-50">
                  -€1,00
                </div>
              )}
            </div>
            <div className="flex flex-col items-center gap-1">
              <div
                onClick={() => setCommunication({ ...communication, template: doubleId })}
                className={`flex h-fit w-fit cursor-pointer items-center justify-center rounded-[20px] border-2 bg-secondary-200 bg-opacity-50 p-4 transition-all ${postcard.template === doubleId ? 'border-primary-300' : 'border-secondary-200'}`}>
                <img
                  alt="Double"
                  src={`${process.env.PUBLIC_URL}/assets/cards/greeting-icon.png`}
                  className="h-14 w-14"
                />
              </div>
              <p>{trans('page.createCommunication.finish.double')}</p>
              {(postcard.template === singleId || !postcard.template) && (
                <div className="rounded-[6px] bg-secondary p-1 text-[13px] text-secondary-50">
                  +€1,00
                </div>
              )}
            </div>
          </div>
        </Labeled>
        <Labeled label={trans('page.createCommunication.finish.finish')}>
          <div className="flex gap-4">
            <div className="flex flex-col items-center gap-1">
              <div
                onClick={() => setCommunication({ ...communication, finish: PostcardFinish.MATTE })}
                className={`flex h-fit w-fit cursor-pointer items-center justify-center rounded-[20px] border-2 bg-secondary-200 bg-opacity-50 p-4 ${postcard.finish === PostcardFinish.MATTE ? 'border-primary-300' : 'border-secondary-200'}`}>
                <div className="h-14 w-14 bg-primary-300" />
              </div>
              <p>{trans('page.createCommunication.finish.matte')}</p>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div
                onClick={() =>
                  setCommunication({ ...communication, finish: PostcardFinish.GLOSSY })
                }
                className={`flex h-fit w-fit cursor-pointer items-center justify-center rounded-[20px] border-2 bg-secondary-200 bg-opacity-50 p-4 ${postcard.finish === PostcardFinish.GLOSSY ? 'border-primary-300' : 'border-secondary-200'}`}>
                <img
                  alt="Double"
                  src={`${process.env.PUBLIC_URL}/assets/cards/glossy-icon.png`}
                  className="h-14 w-14"
                />
              </div>
              <p>{trans('page.createCommunication.finish.glossy')}</p>
            </div>
          </div>
        </Labeled>
      </div>
    </div>
  );
}

function PostcardSendDetailsPanel({ communication, setCommunication }: PanelProps) {
  const postcard = communication as Partial<PostcardOrder>;
  const { t } = useTranslation();
  const { desiredDeliveryDate } = communication;
  const minDate = getFirstPotentialDeliveryDate();
  const expectedDeliveryDate = getExpectedDeliveryDate(desiredDeliveryDate, minDate);

  useEffect(() => {
    if (!desiredDeliveryDate) return;
    setCommunication({
      ...postcard,
      sendDate: getSendDateFromDeliveryDate(expectedDeliveryDate!),
    });
  }, [desiredDeliveryDate]);

  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {t('page.createCommunication.details.title')}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {t('page.createCommunication.details.description')}
        </p>
      </div>
      <Labeled label={t('page.createCommunication.details.deliveryDate')}>
        <DatePicker
          includeTime={false}
          className="w-full md:w-1/2"
          minDate={dayjs(minDate)}
          date={communication.desiredDeliveryDate}
          setDate={(date) => setCommunication({ ...postcard, desiredDeliveryDate: date })}
        />
        {expectedDeliveryDate && !isSameDay(expectedDeliveryDate, desiredDeliveryDate!) && (
          <div>
            {t('page.createCommunication.details.explanation', {
              date: format(expectedDeliveryDate, 'dd-MM-yyyy'),
            })}
          </div>
        )}
      </Labeled>
      <Labeled label={t('page.createCommunication.details.returnAddress')}>
        <AddressPicker
          setSelectedAddress={(a) =>
            setCommunication({ ...communication, senderAddress: a ?? undefined })
          }
          selectedAddress={postcard.senderAddress ?? null}
          addButton
        />
      </Labeled>
    </div>
  );
}

function PostcardPaymentPanel({
  communication,
  setCommunication,
  templates,
  setIndex,
}: PostcardPanelProps) {
  const postcard = communication as Partial<PostcardOrder>;
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [discount, setDiscount] = useState<Discount | undefined>();

  const template = templates.find((te) => te.id === postcard.template);
  const price = template?.format === PostcardFormat.GREETINGCARD_SQ14 ? 4.49 : 3.49;
  const total = (postcard.connectionIds?.length ?? 0) + (postcard.localContactIds?.length ?? 0);
  const { clientSecret } = communication;

  useLayoutEffect(() => {
    if (!clientSecret)
      orderService
        .createPaymentIntent(
          ((postcard.localContactIds?.length ?? 0) + (postcard.connectionIds?.length ?? 0)) * 399,
        )
        .then((secret) => setCommunication({ ...communication, clientSecret: secret }));
  }, []);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col">
        <h1 className="text-[30px] font-semibold leading-[35px]">
          {t('page.createCommunication.payment.title')}
        </h1>
        <p className="text-[16px] text-opacity-50">
          {t('page.createCommunication.payment.description')}
        </p>
      </div>
      <div className="">
        {clientSecret && (
          <Elements
            options={
              {
                appearance: {
                  ...stripeAppearance,
                  variables: { ...stripeAppearance.variables, colorBackground: COLOR_SECONDARY_50 },
                },
                clientSecret,
              } as StripeElementsOptions
            }
            stripe={stripePromise}>
            <CheckoutForm
              clientSecret={clientSecret}
              containerClassName="flex flex-col rounded-[15px] bg-secondary-200 bg-opacity-50 p-4 my-0"
              buttonClassName="mt-4"
              className="mx-auto max-w-xl"
              disabled={disabled}
              setDisabled={setDisabled}
              onSubmit={() =>
                orderService.placePostcardOrder(
                  sanitizeInput({ ...postcard, momentConnectionIds: [] }) as PostcardOrder,
                )
              }>
              <div className="mt-4 flex flex-col gap-4">
                <div className="flex flex-col px-4">
                  <div className="flex justify-between">
                    <p className="text-[15px] font-medium">
                      {total}{' '}
                      {t('page.payment.postcard.card', {
                        count: total,
                      })}
                    </p>
                    <p className="text-[15px] font-medium">€{price * total}</p>
                  </div>

                  <div className="flex justify-between pt-2 text-primary">
                    <p className="text-[15px] font-medium">{t('page.payment.otherDiscounts')}</p>
                    <p className="text-[15px] font-medium">
                      €{(price * total * (discount?.amount ?? 0)).toFixed(2)}
                    </p>
                  </div>
                  <DiscountInput
                    className="mt-4"
                    discount={discount}
                    setDiscount={(d) => {
                      setDiscount(d);
                      setCommunication({ ...communication, code: d?.code });
                    }}
                  />
                </div>
                <div className="mx-4 flex justify-between border-t border-primary-900 pt-4 text-lg font-medium">
                  <p className="text-[15px]">{t('general.total')}</p>
                  <p className="text-[15px]">
                    {/* TODO handle rounding errors */}€
                    {(total * price - price * total * (discount?.amount ?? 0)).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="mt-4 flex justify-end gap-4">
                <Button variant="secondary" onClick={() => setIndex(-1)}>
                  {t('general.back')}
                </Button>
                <CheckoutForm.CheckoutButton>
                  {t('page.payment.payAndConfirm')}
                </CheckoutForm.CheckoutButton>
              </div>
            </CheckoutForm>
          </Elements>
        )}
      </div>
    </div>
  );
}

interface OverviewPanelProps {
  index: number;
  communication: CommunicationInput;
  communicationType: CommunicationType;
  templates: PostcardTemplate[];
  setCommunication: (content: CommunicationInput) => void;
}

function OverviewPanel(props: OverviewPanelProps) {
  const { communicationType } = props;
  switch (communicationType) {
    case CommunicationType.EMAIL:
    case CommunicationType.NOTIFICATION:
      return <></>;
    case CommunicationType.POSTCARD:
      return <PostcardOverviewPanelProps {...props} />;
    default:
      return <></>;
  }
}

function PostcardOverviewPanelProps({
  communication,
  index,
  templates,
  communicationType,
  setCommunication,
}: OverviewPanelProps) {
  const [view, setView] = useState<'TEXT' | 'THUMBNAIL'>('THUMBNAIL');
  const postcard = communication as Partial<PostcardOrder>;
  const template = templates.find(
    (t) =>
      t.name === communication.templateName &&
      (postcard.template ? t.id === postcard.template : t.format === PostcardFormat.POSTCARD_SQ15),
  );
  const price = template?.format === PostcardFormat.GREETINGCARD_SQ14 ? 4.49 : 3.49;

  if (index === 0) return <></>;

  return (
    <div
      className={`left-0 top-32 mb-4 flex max-h-[60vh] flex-col gap-4 transition-all md:sticky ${!template?.thumbnail ? 'w-0 overflow-hidden' : 'w-full'}`}>
      <div className="flex h-full flex-col items-center justify-center gap-4 rounded-[20px] bg-secondary-200 bg-opacity-50 p-8 transition-all">
        <div className="flex h-full w-full flex-1 flex-col items-center justify-center gap-4 rounded-[15px] bg-secondary-50 p-2 sm:p-10">
          <div className="relative h-fit w-fit">
            {view === 'THUMBNAIL' ? (
              <ApiImage
                path={template?.thumbnail ?? ''}
                className="h-[250px] w-[250px] flex-shrink-0 flex-grow-0"
              />
            ) : template?.format === PostcardFormat.GREETINGCARD_SQ14 ? (
              <div className="flex">
                <div className="h-[170px] w-[170px] border-r-2 border-black border-opacity-50 bg-white" />
                <div className="flex h-[170px] w-[170px] items-center justify-center text-wrap break-all bg-white p-2 text-[10px]">
                  {postcard.message}
                </div>
              </div>
            ) : (
              <div className="flex h-[250px] w-[250px] gap-2 bg-white">
                <div className="flex h-full w-full flex-1 items-center text-wrap break-all p-2 text-[10px]">
                  {postcard.message}
                </div>
                <div className="flex h-full w-full flex-1 items-center justify-center text-[9px]">
                  <p>
                    Firstname Lastname <br /> Street 123 <br />
                    1234AB Place
                    <br />
                    Country
                  </p>
                </div>
              </div>
            )}
            <div className="absolute -bottom-3.5 left-1/2 -translate-x-1/2 transform rounded-[6px] bg-primary-300 p-1 text-[15px] font-medium text-secondary-50">
              €{price}
            </div>
          </div>
        </div>
      </div>{' '}
      {index > 1 && (
        <div className="mx-auto mt-2 flex gap-4">
          <Button
            variant="tertiary"
            className="rounded-full p-3"
            onClick={() => setView('THUMBNAIL')}
            disabled={view === 'THUMBNAIL'}>
            <FaArrowLeft className="h-5 w-5" />
          </Button>
          <Button
            variant="tertiary"
            className="rounded-full p-3"
            onClick={() => setView('TEXT')}
            disabled={view === 'TEXT'}>
            <FaArrowRight className="h-5 w-5" />
          </Button>
        </div>
      )}
    </div>
  );
}
