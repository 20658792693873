import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  BusinessInvite,
  CustomFieldResponses,
  CustomFieldType,
  CustomMultipleChoiceField,
} from '../../../services/model/inviteService.model';
import { createEmptyBusinessInvite } from '../../../utils/formatUtils';
import { ProfileDataType, ProfileField } from '../../../types/Profile';
import { isCommunity, isMoments } from '../../../constants';
import InviteForm from '../../components/forms/InviteForm';
import UserDataForm from '../../components/forms/userData/UserDataForm';
import useSelectedBusiness from '../../../hooks/business/useSelectedBusiness';
import InvitationLink from '../../components/misc/InvitationLink';
import inviteService from '../../../services/inviteService';
import adminService from '../../../services/adminService';
import useNavigateAfterBusinessChange from '../../../hooks/effects/useNavigateAfterBusinessChange';
import { filterCustomLabelFields } from '../../../utils/filterUtils';
import CustomFieldsForm from '../../components/forms/CustomFieldsForm';
import userService from '../../../services/userService';
import StepFlow, { StepFlowStep } from '../../components/misc/StepFlow';

export default function InviteLinkPage() {
  const location = useLocation();
  const navigate = useNavigate();
  useNavigateAfterBusinessChange();

  useEffect(() => {
    if (!location.state) navigate('/overview');
  }, []);

  if (!location.state) return <></>;
  return location.state.view === 'SETUP' ? <InviteLinkSetup /> : <LinkView />;
}

function InviteLinkSetup() {
  const business = useSelectedBusiness();

  const [invite, setInvite] = useState<BusinessInvite>(createEmptyBusinessInvite());
  const [sharedData, setSharedData] = useState<ProfileField[]>([]);
  const [customFieldResponses, setCustomFieldResponses] = useState<CustomFieldResponses>([]);
  const [step, setStep] = useState<number>(business?.inviteFields ? (isMoments ? 2 : 1) : 0);

  const handleStep = (i: 1 | -1) => setStep(step + i);

  const steps = [
    <InviteView invite={invite} setInvite={setInvite} setStep={handleStep} />,
    <SharedDataView
      sharedData={sharedData}
      setStep={handleStep}
      setSharedData={setSharedData}
      customFieldResponses={customFieldResponses}
      setCustomFieldResponses={setCustomFieldResponses}
      invite={business?.inviteFields}
    />,
    <LinkView setStep={handleStep} />,
  ];

  useEffect(() => {
    if (!business?.inviteFields) return;
    adminService.getSharedData().then(setSharedData);
    inviteService.fetchInvite().then(setInvite);
  }, []);

  useEffect(() => {
    // remove all removed custom fields
    setCustomFieldResponses(
      (business?.inviteFields?.customFields ?? []).reduce((acc, field) => {
        acc[field.customFieldId!] = customFieldResponses[field.customFieldId!] ?? '';
        return acc;
      }, {} as CustomFieldResponses),
    );
  }, [JSON.stringify(business?.inviteFields?.customFields)]);

  return <StepFlow steps={steps} indexState={[step, setStep]} />;
}

interface SharedDataViewProps {
  sharedData: ProfileField[];
  setSharedData: (sharedData: ProfileField[]) => void;
  customFieldResponses: CustomFieldResponses;
  setCustomFieldResponses: (customFieldResponses: CustomFieldResponses) => void;
  invite: BusinessInvite | undefined;
  setStep: (i: 1 | -1) => void;
}

function SharedDataView({
  sharedData,
  setSharedData,
  customFieldResponses,
  setCustomFieldResponses,
  invite,
  setStep,
}: SharedDataViewProps): JSX.Element {
  const { t } = useTranslation();
  const { customFields, nonRequestedFields, mandatoryFields } = invite ?? {};
  const business = useSelectedBusiness();

  const handleSubmit = async () => {
    if (isMoments) {
      await adminService.updateSharedData(sharedData.map((f) => +f.id!));
      setStep(1);
      return;
    }
    if (!invite || !business?.inviteFields) return;
    const mandatoryCustom = business.inviteFields.customFields.filter((f) => f.mandatory);
    if (
      mandatoryCustom.some((f) => !customFieldResponses[f.customFieldId!]) ||
      mandatoryFields!.some((f) => !sharedData.find((d) => d.dataType === f))
    ) {
      toast.error(t('toast.error.mandatoryStar'));
      return;
    }
    userService
      .updateBusinessConnection(business.businessId!, {
        propertyIds: sharedData.map((f) => f.id!),
        customFieldResponses,
        dataForMembers: sharedData.map((f) => f.id!),
      })
      .then(() => setStep(1));
  };

  return (
    <StepFlowStep
      setStep={setStep}
      showNext={false}
      onSubmit={handleSubmit}
      title={t('page.inviteLink.sharedData.title')}
      subtitle={t('page.inviteLink.sharedData.subtitle', { name: business?.name })}>
      <UserDataForm
        selectedData={sharedData}
        setSelectedData={setSharedData}
        onlyBirthDate={isMoments || !nonRequestedFields?.includes(ProfileDataType.BIRTHDATE)}
        hasSelectAll={isMoments}
        {...(isCommunity
          ? {
              nonRequestedFields: [
                ...(nonRequestedFields ?? []),
                ProfileDataType.MOMENT,
                ProfileDataType.SOCIAL,
              ],
              mandatoryFields,
              onePerDataType: true,
            }
          : {})}
      />
      {isCommunity && (
        <CustomFieldsForm
          includeMandatory
          fields={customFields ?? []}
          response={customFieldResponses}
          setResponse={setCustomFieldResponses}
        />
      )}
    </StepFlowStep>
  );
}

interface InviteViewProps {
  invite: BusinessInvite;
  setInvite: (invite: BusinessInvite) => void;
  setStep: (i: 1 | -1) => void;
}

function InviteView({ invite, setInvite, setStep }: InviteViewProps): JSX.Element {
  const { t } = useTranslation();
  const business = useSelectedBusiness();

  const handleSubmit = async (): Promise<void> => {
    const { customFields } = invite;
    const labelFields = filterCustomLabelFields(customFields);
    if (
      labelFields.some((field): boolean => !field.label) ||
      customFields
        .filter((x) => x.type === CustomFieldType.MULTIPLE_CHOICE)
        .map((x) => (x as CustomMultipleChoiceField).options)
        .flat()
        .some((option): boolean => !option)
    ) {
      toast.error(t('toast.error.allFields'));
      return;
    }
    if (
      Array.from(new Set(labelFields.map((field) => field.label))).length !== labelFields.length
    ) {
      toast.error(t('toast.error.noTwoSame'));
      return;
    }

    await Promise.all([
      business?.inviteFields
        ? inviteService.updateInvite(invite)
        : inviteService.createInvite(invite),
    ]);
    if (isMoments && !business?.inviteFields) await inviteService.createInviteLink();
    setStep(1);
  };

  return (
    <StepFlowStep
      title={t('page.inviteLink.invite.title')}
      subtitle={t('page.inviteLink.invite.subtitle')}
      setStep={setStep}
      showNext={false}
      onSubmit={handleSubmit}
      showPrev={false}
      className="mt-6">
      <InviteForm invite={invite} setInvite={setInvite} />
    </StepFlowStep>
  );
}

interface LinkViewProps {
  setStep?: (i: 1 | -1) => void;
}

function LinkView({ setStep }: LinkViewProps) {
  const business = useSelectedBusiness();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMoments && !business?.inviteFields?.friendlyId) inviteService.createInviteLink();
  }, []);

  return (
    <StepFlowStep
      setStep={setStep ?? (() => {})}
      showPrev={!!setStep}
      className="max-w-full"
      showNext={false}
      submitText={t('page.inviteLink.toDashboard')}
      onSubmit={() => navigate('/overview')}>
      <div className="mx-auto my-auto flex h-full w-full flex-1 flex-col items-center justify-center gap-2 md:w-[70%] md:flex-row md:gap-0">
        <img
          alt="Vera"
          src={`${process.env.PUBLIC_URL}/assets/figures/party_share.svg`}
          className="w-[40%]"
        />
        <div className="flex w-full flex-col gap-2">
          <h1 className="font-serif text-3xl font-semibold">{t('page.inviteLink.title')}</h1>
          <InvitationLink />
        </div>
      </div>
    </StepFlowStep>
  );
}
