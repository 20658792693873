import React, { useState } from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import SearchBar from '../forms/SearchBar';
import ProfilePicture from './ProfilePicture';
import { ConnectionsListConnection } from '../../../types/misc';

interface ConnectionsListProps<T> {
  connections: ConnectionsListConnection[];
  selected: T[];
  setSelected: (selected: T[]) => void;
  includePicture?: boolean;
  className?: string;
  containerClassName?: string;
  hasSearch?: boolean;
  search?: string;
  light?: boolean;
  equalityFunction?: (a: ConnectionsListConnection, b: T) => boolean;
  disabledMessage?: string;
}

// eslint-disable-next-line no-empty-pattern
export default function ConnectionsList<T>({
  connections,
  selected,
  setSelected,
  includePicture = false,
  className = '',
  containerClassName = '',
  hasSearch = true,
  search: predefinedSearch,
  equalityFunction,
  light = false,
  disabledMessage,
}: ConnectionsListProps<T>): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [showSelected, setShowSelected] = useState(false);
  const isInArray = (array: any[], elementToCheck: any) =>
    _.some(array, (item) =>
      equalityFunction
        ? equalityFunction(item, elementToCheck)
        : _.isObject(item)
          ? _.isEqual(item, elementToCheck)
          : item === elementToCheck,
    );

  const handleSelectAll = () => {
    if (selected.length === connections.filter((c) => !c.disabled).length) {
      setSelected([]);
    } else {
      setSelected(connections.filter((c) => !c.disabled).map((c) => c.id));
    }
  };

  const handleSelect = (id: any) => {
    if (isInArray(selected, id)) {
      setSelected(
        selected.filter((s) => (equalityFunction ? !equalityFunction(id, s) : !_.isEqual(s, id))),
      );
    } else {
      setSelected([...selected, id]);
    }
  };
  const filtered = connections
    .filter((c) => c.alias.toLowerCase().includes((predefinedSearch ?? search).toLowerCase()))
    .filter((c) => !showSelected || isInArray(selected, c.id));

  return (
    <div
      className={twMerge('flex w-full flex-col gap-4 border-y border-primary-300 py-4', className)}>
      <div className="flex items-center justify-between gap-2">
        {connections.length > 4 && hasSearch && !predefinedSearch && (
          <SearchBar
            inputProps={{ className: `${light && '!bg-secondary-50'}` }}
            search={search}
            setSearch={setSearch}
          />
        )}
        <p
          onClick={() => setShowSelected(!showSelected)}
          className="cursor-pointer whitespace-nowrap text-[13px] font-semibold">
          {t(`form.userData.show${showSelected ? 'All' : 'Selected'}`)}{' '}
          {!showSelected && `(${selected.length})`}
        </p>
        {filtered.length > 2 && (
          <p
            onClick={handleSelectAll}
            className="cursor-pointer flex-nowrap whitespace-nowrap text-[13px] font-semibold text-primary-300">
            {t('form.userData.selectAll')}
          </p>
        )}
      </div>
      <div
        className={twMerge(
          'no-scrollbar flex max-h-[400px] flex-col gap-2 overflow-y-scroll',
          light && 'rounded-[15px] bg-secondary-50 px-2 py-2',
          containerClassName,
        )}>
        <p className={classNames('pl-2', { hidden: filtered.length })}>
          {t('component.connectionSelector.noConnections')}
        </p>
        {filtered.map((c, i) => (
          <div
            key={i}
            onClick={() => !c.disabled && handleSelect(c.id)}
            className={classNames(
              'flex cursor-pointer items-center justify-between rounded-[8px] border border-secondary-200 px-3 py-2 transition-all',
              {
                'bg-secondary-200': isInArray(selected, c.id),
                'border-opacity-20': c.disabled,
              },
            )}>
            <div className="flex items-center gap-2">
              {includePicture && (
                <ProfilePicture
                  alias={c.alias}
                  picture={c.picture}
                  className={classNames('h-9 w-9', { 'opacity-20': c.disabled })}
                />
              )}
              <p className={classNames('font-medium', { 'opacity-20': c.disabled })}>
                {c.alias.replace('+', ' ')}
              </p>
              {disabledMessage && c.disabled && (
                <div className="rounded-[6px] bg-danger bg-opacity-50 p-0.5 text-[10px] font-semibold text-secondary-50">
                  {disabledMessage}
                </div>
              )}
            </div>
            <input
              type="checkbox"
              className={c.disabled ? 'opacity-20' : ''}
              checked={isInArray(selected, c.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
