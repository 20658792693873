import { twMerge } from 'tailwind-merge';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  search: string;
  setSearch: (search: string) => void;
  className?: string;
  inputProps?: Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'type'>;
}

/*
 * A search bar component.
 *
 * @param {() => void} onChange - The function to execute on change.
 * @returns {JSX.Element} - The search bar component.
 */
export default function SearchBar({
  search,
  setSearch,
  className = '',
  inputProps: { className: classNameInput, placeholder, ...inputProps } = {},
}: SearchBarProps): JSX.Element {
  const { t } = useTranslation('');

  return (
    <div data-testid="searchbar" className={twMerge('relative flex min-h-10 w-full', className)}>
      <div className="absolute inset-y-0 left-0 top-1/2 -translate-y-1/2 flex items-center pl-3">
        <FaSearch data-testid="magnify" className="icon h-4 w-4 text-primary" />
      </div>
      <input
        type="search"
        className={twMerge('pl-10 !h-[40px]', classNameInput)}
        value={search}
        placeholder={placeholder ?? t('general.search')}
        onChange={(e) => setSearch(e.target.value)}
        {...inputProps}
      />
    </div>
  );
}
