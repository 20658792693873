import React from 'react';
import { IconType } from 'react-icons';
import { TbPlus } from 'react-icons/tb';
import { LinkProps } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import IconLink from '../links/IconLink';
import UnderlinedLink from '../links/UnderlinedLink';

export default function OverviewCard({ icon: Icon, title, className, mainFigure, totalCount, createLinkProps, seeMoreText, seeMoreLinkProps, children }: {
  icon: IconType,
  title: string,
  className?: string,
  mainFigure?: number,
  totalCount?: number,
  createLinkProps?: Omit<LinkProps, 'children'>,
  seeMoreLinkProps?: Omit<LinkProps, 'children'>,
  seeMoreText?: string,
  children?: React.ReactNode
}): JSX.Element {
  return (
    <div className={twMerge('rounded-xl flex flex-col gap-4 p-4 bg-secondary-200 bg-opacity-50', className)}>
      <div className="flex gap-2">
        <Icon className="flex-shrink-0 mr-2 text-primary-900" size={22} />
        <div className="flex-1">
          <h3 className=" font-semibold">
            {title}
            {totalCount !== undefined && <span className="text-gray-400"> ({totalCount})</span>}
          </h3>
          <p className="text-4xl">{mainFigure}</p>
        </div>
        <div className="flex gap-1">
          {createLinkProps && <IconLink icon={TbPlus} {...createLinkProps} />}
        </div>
      </div>
      <span className="flex-1">
        {children}
      </span>
      <div className="flex align-bottom justify-end w-full z-10">
        {seeMoreLinkProps && <UnderlinedLink {...seeMoreLinkProps}>{seeMoreText}</UnderlinedLink>}
      </div>
    </div>
  );
}
