import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export type Pagination = {
  page: number;
  perPage: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
  numberOfPages: number;
};

export const usePagination = <T>(allItems: T[]): { items: T[] } & Pagination => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [items, setItems] = useState<T[]>([]);

  useEffect(() => {
    setItems(allItems.slice((page - 1) * perPage, page * perPage));
  }, [allItems, page, perPage]);

  return {
    page,
    perPage,
    setPage,
    setPerPage,
    numberOfPages: Math.ceil(allItems.length / perPage),
    items,
  };
}
