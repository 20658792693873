import _ from 'lodash';
import { getSelectedBusiness } from '../hooks/business/useSelectedBusiness';
import {
  deleteEmail,
  deleteNotification,
  setEmails,
  setNotifications,
  setPostcards,
  updateEmail,
  updateNotification,
} from '../redux/slices/communicationsSlice';
import { store } from '../redux/store';
import { PostcardOrder, ScheduledEmail, ScheduledNotification } from '../types/communication';
import httpService from './httpService';

function getEmails(): Promise<ScheduledEmail[]> {
  return httpService<ScheduledEmail[]>('/business/communication/email').then(({ data }) => {
    store.dispatch(setEmails({ businessId: getSelectedBusiness()?.businessId!, emails: data }));
    return data;
  });
}

function getNotifications(): Promise<ScheduledNotification[]> {
  return httpService<ScheduledNotification[]>('/business/communication/notification').then(
    ({ data }) => {
      store.dispatch(
        setNotifications({ businessId: getSelectedBusiness()?.businessId!, notifications: data }),
      );
      return data;
    },
  );
}

function getPostcards(): Promise<PostcardOrder[]> {
  return httpService<PostcardOrder[]>('/business/order').then(({ data }) => {
    store.dispatch(
      setPostcards({ businessId: getSelectedBusiness()?.businessId!, postcards: data }),
    );
    return data;
  });
}

function createScheduledNotification(notification: ScheduledNotification): Promise<void> {
  return httpService.post('/business/communication/notification', notification).then(({ data }) => {
    store.dispatch(
      updateNotification({
        businessId: getSelectedBusiness()?.businessId!,
        notification: data,
      }),
    );
  });
}

function createScheduledEmail(email: ScheduledEmail, isRecurring: boolean = false): Promise<void> {
  return httpService
    .post<ScheduledEmail[]>('/business/communication/email', { ...email, isRecurring })
    .then(({ data }) => {
      data.map((e) =>
        store.dispatch(
          updateEmail({
            businessId: getSelectedBusiness()?.businessId!,
            email: e,
          }),
        ),
      );
    });
}

function sendEmail(email: ScheduledEmail, isRecurring: boolean = false): Promise<void> {
  return httpService
    .post<
      ScheduledEmail[]
    >('/business/communication/email/direct', _.omit({ ...email, isRecurring }, 'sendDate'))
    .then(({ data }) => {
      data.map((e) =>
        store.dispatch(
          updateEmail({
            businessId: getSelectedBusiness()?.businessId!,
            email: e,
          }),
        ),
      );
    });
}

function deleteScheduledNotification(notificationId: number): Promise<void> {
  return httpService.delete(`/business/communication/notification/${notificationId}`).then(() => {
    store.dispatch(
      deleteNotification({ businessId: getSelectedBusiness()?.businessId!, notificationId }),
    );
  });
}

function deleteScheduledEmail(emailId: number): Promise<void> {
  return httpService.delete(`/business/communication/email/${emailId}`).then(() => {
    store.dispatch(deleteEmail({ businessId: getSelectedBusiness()?.businessId!, emailId }));
  });
}

function updateScheduledNotification(notification: ScheduledNotification): Promise<void> {
  return httpService
    .put<ScheduledNotification>(`/business/communication/notification`, notification)
    .then(({ data }) => {
      store.dispatch(
        updateNotification({
          businessId: getSelectedBusiness()?.businessId!,
          notification: data,
        }),
      );
    });
}

function updateScheduledEmail(email: ScheduledEmail): Promise<void> {
  return httpService
    .put<ScheduledEmail>(`/business/communication/email`, email)
    .then(({ data }) => {
      store.dispatch(
        updateEmail({
          businessId: getSelectedBusiness()?.businessId!,
          email: data,
        }),
      );
    });
}

export default {
  getEmails,
  getNotifications,
  createScheduledNotification,
  createScheduledEmail,
  deleteScheduledNotification,
  deleteScheduledEmail,
  updateScheduledNotification,
  updateScheduledEmail,
  getPostcards,
  sendEmail,
};
